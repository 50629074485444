<template>
  <div class="contents">
    <div class="con-body" ref="container">
        <div class="con-drone-view" ref="bigView">
            <div class="remote-station-title">
                <h3>{{stationInfo == null || stationInfo.managerData == null || stationInfo.managerData.tag == null ? '-' : stationInfo.managerData.tag + $t('station-number') + stationInfo.deviceName}}</h3>
            </div>
            <div class="con-live-view" ref="mapRoot">
              <Map ref="map" style="width: 100%; height: 100%;" :loadMapName="loadMap"/>
              <MapButtonComponent ref="buttonMap"/>
            </div>
            <!-- camera toggle 20241127 -->
            <div class="camera-toggle">
              <div class="camera-switching-btn">
                <button :class="{ active: isCameraShot }" @click="setChangeCameraMode('Shot')">
                  <img src="@/assets/images/icon_svg/ic_camera.png" alt="icon">
                </button>
                <button :class="{ active: !isCameraShot }" @click="setChangeCameraMode('Video')" style="margin-bottom: 0;">
                  <img src="@/assets/images/icon_svg/ic_video.png" alt="icon">
                </button>
              </div>
              <div class="shot-content" v-if="false">
                <div v-if="isCameraShot" class="camera-shot">
                  <div class="camera-shot-btn" @click="capturePhoto"></div>
                  <h4>SHOT</h4>
                </div>
                <div v-else class="video-shot">
                  <div class="video-shot-btn" :class="{ 'recording': isRecording }" @click="toggleRecording"></div>
                  <h4>{{ formattedTime }}</h4>
                </div>
              </div>
            </div>
            <!-- camera massage 20241127 -->
            <div v-if="showMessage" class="camera-massage">
              <h4>{{ $t("station-mode-camera-save") }}</h4>
            </div>
            <div v-if="playMessage" class="video-massage">
              <h4 v-if="isRecording">{{ $t("station-mode-video-play") }}</h4>
              <h4 v-else>{{ $t("station-mode-video-Stop") }}</h4>
            </div>
            <!-- all drone remote -->
            <div class="sdr-con">
                <!-- station remote -->
                <div class="sr-con">
                    <div class="sr-btn">
                        <div class="sr-btn-title">
                            <h2><input type="checkbox" v-model="isStationChecked" @change="checkedStationDebug">{{ $t("station-mode-check-remote") }}</h2>
                        </div>
                        <div v-if="!isStationChecked" class="sr-btn-false">
                            <h4>{{ $t("station-mode-check-remote-comment") }}</h4>
                        </div>
                        <div class="sr-btn-list" v-else>
                            <ul class="m-b-10">
                                <li>
                                    <div v-show="!isDoorOpened" class="sr-btn-box" @click="handleDoorOpen(true)">
                                        <div class="sr-btn-icon"><img src="@/assets/images/icon_svg/ic_openDock.png" alt="icon"></div>
                                        <h4>{{ $t("station-mode-btn-dork-open") }}</h4>
                                    </div>
                                    <div v-show="isDoorOpened" class="sr-btn-box" @click="handleDoorOpen(false)">
                                        <div class="sr-btn-icon"><img src="@/assets/images/icon_svg/ic_closeDock.png" alt="icon"></div>
                                        <h4>{{ $t("station-mode-btn-dork-close") }}</h4>
                                    </div>
                                </li>
                                <li>
                                  <div v-show="!isDroneOnline" class="sr-btn-box" @click="handleDroneOpen(true)">
                                    <div :class="['sr-btn-icon', { 'flashing': isFlashing }]" ><img src="@/assets/images/icon_svg/drone_icon.png" alt="icon" style="width: 25px;"></div>
                                    <h4>{{ $t("station-mode-btn-drone-on") }}</h4>
                                  </div>
                                  <div v-show="isDroneOnline" class="sr-btn-box" @click="handleDroneOpen(false)">
                                    <div :class="['sr-btn-icon', { 'flashing': isFlashing }]" ><img src="@/assets/images/icon_svg/drone_icon.png" alt="icon" style="width: 25px;"></div>
                                    <h4>{{ $t("station-mode-btn-drone-off") }}</h4>
                                  </div>
                                </li>
                            </ul>
                        </div>
                        <div class="sr-btn-list">
                          <ul>
                            <li>
                              <div v-if="!isFlightStarted" class="sr-btn-box" @click="openDroneTakeoffModal">
                                <div class="sr-btn-icon"><img src="@/assets/images/icon_svg/ic_flightStart.png" alt="icon"></div>
                                <h4>{{ $t("station-mode-btn-fly-start") }}</h4>
                              </div>
                              <div v-if="isFlightStarted" class="sr-btn-box" @click="confirmFlightStop">
                                <div class="sr-btn-icon"><img src="@/assets/images/icon_svg/ic_flightPause.png" alt="icon"></div>
                                <h4>{{ $t("station-mode-btn-fly-stop") }}</h4>
                              </div>
                            </li>
                            <li>
                              <div class="sr-btn-box" @click="flagCoordinate">
                                <div class="sr-btn-icon" :style=" isCoordinateFlag ? 'background:#ff0909;' : ''">
                                  <img src="@/assets/images/icon_svg/ic_locationFlight.png" alt="icon"
                                  ></div>
                                <h4>{{ $t("station-mode-btn-fly-coordinate") }}</h4>
                              </div>
                            </li>

                          </ul>
                        </div>
                    </div>
                </div>
                <!-- drone remote -->
                <div class="dr-con">
                    <div class="dr-btn add-btn-title">
                        <div class="dr-btn-title add-btn-title">
                            <h2><input ref="manualRemote" type="checkbox" v-model="isDroneFightControlChecked" @change="onClickFightControl">{{ $t("station-mode-check-control") }}</h2>
                            <div class="sr-btn-box">
<!--                              <div class="sr-btn-icon add-btn-title" @click="setChangeCameraMode('Video')"><span> 카메라 비디오 변경</span></div>-->
                              <div class="sr-btn-icon add-btn-title" :class="isFlashing ? 'flashing' : ''" @click="handleRTHClick"><img src="@/assets/images/icon_svg/ic_return_home.png" alt="icon" style="width: 25px;"></div>
                            </div>
                        </div>
                        <div v-if="!this.useManualControlState.stationFlightController" class="dr-btn-false">
                            <h4>{{ $t("station-mode-check-control-comment") }}</h4>
                        </div>
                        <div class="dr-btn-form" v-else>
                            <div class="lf-btn">
                                <div class="altitude-value">
                                    <span>{{$t("monitoring-data-alt")}}</span>
                                    <h4><span>{{ storeDroneInfo == null ? "-" : storeDroneInfo.elevation.toFixed(1) }}</span>m</h4>
                                </div>
                                <div class="at-key">
                                    <div class="at-key-row">
                                      <button class="key-btn" :class="{ active: activeKeys.includes('KeyR') }">
                                        <div class="key-img">
                                          <img src="@/assets/images/icon_svg/up_key.png" alt="Up Key">
                                        </div>
                                        <h6>R</h6>
                                      </button>
                                      <button class="key-btn" :class="{ active: activeKeys.includes('KeyF') }">
                                        <div class="key-img">
                                          <img src="@/assets/images/icon_svg/down_key.png" alt="Down Key">
                                        </div>
                                        <h6>F</h6>
                                      </button>
                                    </div>
                                    <div class="at-key-row">
                                        <button class="key-btn" :class="{ active: activeKeys.includes('KeyQ') }">
                                            <div class="key-img">
                                                <img src="@/assets/images/icon_svg/r_left.png">
                                            </div>
                                            <h6>Q</h6>
                                        </button>
                                        <button class="key-btn" :class="{ active: activeKeys.includes('KeyE') }">
                                            <div class="key-img">
                                                <img src="@/assets/images/icon_svg/r_right.png">
                                            </div>
                                            <h6>E</h6>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="can-btn">
                                <div class="at-img">
                                  <img src="@/assets/images/altitude_box2.png">
                                  <div ref="altitude_box_arrow" style="position: absolute; top: 50%;">
                                    <img src="@/assets/images/altitude_box_arrow.png">
                                  </div>
                                </div>
                                <div class="angle-info">
                                    <h4><span>{{ droneAngle }}</span>˚</h4>
                                    <div class="angle-img">
                                        <img :style="{ transform: 'translate(-50%, 0%) rotate(' + droneRotationAngle + 'deg)' }" src="@/assets/images/angle_circle.png" class="angle_img">
                                        <img src="@/assets/images/icon_svg/ic_droneMark.png" class="droneMark_img">
                                        <img src="@/assets/images/icon_svg/location_mark.png" class="lm_img">
                                    </div>
                                </div>
                            </div>
                            <div class="rf-btn">
                                <div class="op-btn">
                                    <button :class="{ active: isActive[0] }" @click="toggleDroneActive(0)"><img src="@/assets/images/icon_svg/ic_beacon.png"></button>
                                    <button :class="{ active: isActive[1] }" @click="toggleDroneActive(1)"><img src="@/assets/images/icon_svg/ic_light.png"></button>
                                    <button :class="{ active: isActive[2] }" @click="toggleDroneActive(2)"><img src="@/assets/images/icon_svg/ic_laser.png"></button>
                                    <button :class="{ active: isActive[3] }" @click="toggleDroneActive(3)"><img src="@/assets/images/icon_svg/ic_speaker.png"></button>
                                </div>
                                <div class="dt-key">
                                    <div class="dt-key-row">
                                        <button class="key-btn" :class="{ active: activeKeys.includes('KeyW') }">
                                            <div class="key-img">
                                                <img src="@/assets/images/icon_svg/up_key.png">
                                            </div>
                                            <h6>W</h6>
                                        </button>
                                    </div>
                                    <div class="dt-key-row">
                                        <button class="key-btn" :class="{ active: activeKeys.includes('KeyA') }">
                                            <div class="key-img">
                                                <img src="@/assets/images/icon_svg/left_key.png">
                                            </div>
                                            <h6>A</h6>
                                        </button>
                                        <button class="key-btn" :class="{ active: activeKeys.includes('KeyS') }">
                                            <div class="key-img">
                                                <img src="@/assets/images/icon_svg/down_key.png">
                                            </div>
                                            <h6>S</h6>
                                        </button>
                                        <button class="key-btn" :class="{ active: activeKeys.includes('KeyD') }">
                                            <div class="key-img">
                                                <img src="@/assets/images/icon_svg/right_key.png">
                                            </div>
                                            <h6>D</h6>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- camera remote -->
                <div class="cr-con">
                    <div class="cr-btn">
                        <div class="cr-btn-title">
                            <h2><input ref="cameraGimbal" type="checkbox" v-model="isCameraControlChecked" @change="onClickCameraControl">{{ $t("station-mode-check-camera") }}</h2>
                            <div v-if="isCameraControlChecked" class="angle-select">
                              <select v-model="gimbalSendValue">
                                <option :value="0.1">0.1˚</option>
                                <option :value="1.0">1˚</option>
                                <option :value="3.0">3˚</option>
                                <option :value="5.0">5˚</option>
                                <option :value="10.0">10˚</option>
                              </select>
                              <img src="@/assets/images/icon_svg/dropdown-menu-btn_w.png">
                            </div>
                        </div>
                        <div v-if="!isCameraControlChecked" class="cr-btn-false">
                            <h4>{{ $t("station-mode-check-camera-comment") }}</h4>
                        </div>
                        <div class="cr-radar-form" v-else>
                            <div class="cr-radar">
                                <!-- angle value 추가 20241126 -->
                                <div class="cr-angle-value">
                                  <img src="@/assets/images/icon_svg/ic_angle.png" style="width: 15px;">
                                  <h4><span>{{gimbalSendValue}}</span>˚</h4>
                                </div>
                                <div class="cr-radar-date">
                                    <h4><img src="@/assets/images/icon_svg/ic_rotateZ.png" style="width: 15px;"><span>{{ rotationCameraAngle == undefined ? 0.00 : rotationCameraAngle.toFixed(2) }}</span>˚</h4>
                                    <h4><img src="@/assets/images/icon_svg/ic_rotateY.png" style="height: 15px;padding-left: 3px;"><span>{{ rotationDronePitchAngle == undefined ? 0.00 : rotationDronePitchAngle.toFixed(2) }}</span>˚</h4>
                                </div>
                                <div class="cr-radar-img">
                                    <div class="cr-radar-axis" :style="{ transform: `translate(-50%, -50%) rotateX(${rotationXAxisAngle}deg) rotateY(${rotationYAxisAngle}deg) rotateZ(${rotationCameraAngle}deg)` }">
                                        <img src="@/assets/images/icon_svg/ic_cr_radar.svg">
                                    </div>
                                </div>
                            </div>
                            <div class="cr-key">
                              <template  v-for="gimbalKey in Object.values(GimbalKeyCode)">
                                <button :key="gimbalKey" class="key-btn" :class="{ active: activeKeys.includes(gimbalKey) }">
                                  <div class="key-img">
                                    <img v-if="gimbalKey === 'KeyZ'" src="@/assets/images/icon_svg/left_key.png">
                                    <img v-if="gimbalKey === 'KeyX'" src="@/assets/images/icon_svg/up_key.png">
                                    <img v-if="gimbalKey === 'KeyC'" src="@/assets/images/icon_svg/down_key.png">
                                    <img v-if="gimbalKey === 'KeyV'" src="@/assets/images/icon_svg/right_key.png">
                                  </div>
                                  <h6>{{gimbalKey.replace("Key","")}}</h6>
                                </button>
                              </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="con-drone-set">
            <button @click="controlModeOFf"><img src="@/assets/images/icon_svg/ic_control_off.png" alt="icon" />{{ $t("station-mode-btn-mode-out") }}</button>

            <!--- 드론-->
            <div class="con-live-box">
                <div class="vodview" ref="smView">
                    <button class="switching-btn" @click="switchViews">
                        <img src="@/assets/images/icon_svg/ic_switching.png">
                    </button>
                    <div class="video-box" ref="video"> <!--영상 송출 유무 표현-->
                      <div class="play-on" v-if="droneChannelInfo != null">
                        <WebRTC_WOWZA
                            ref="dronePlayer"
                            style="position: relative"
                                      class="play-on"
                                      v-if="droneChannelInfo != null"
                                      v-show="droneChannelInfo.isLoad == true"
                                      :sdpUrl="droneChannelInfo.streamServer.properties.sdpUrl"
                                      :applicationName=" (droneChannelInfo.assignedDeviceType === 'Drone' || droneChannelInfo.assignedDeviceType === 'StationDrone' )? droneChannelInfo.streamServer.properties.droneApplication : droneChannelInfo.streamServer.properties.application"
                                      :streamName="droneChannelInfo.streamKey"
                                      :playerId="droneChannelInfo.channelId"
                                      :deviceType="droneChannelInfo.assignedDeviceType"
                                      :isMutedWebRtc = "true"
                                      :isShowLiveText = "false"
                                      :isSpeakerButtonShow = "false"
                                      :isModelButtonShow = "false"
                                      @isLoadedmetadata="playerLoadData"
                                      @playerRemoveId="playerRemoveId"
                        />
                        <!--                <videoPlayer :src="droneChannelManagerInfo.streamUrl" :isVod="false" :playerId="droneChannelManagerInfo.channelId" :isAuto ="true" :isDeleteButtonShow="false" :contentsId="null" :isControls="false" />-->
                      </div>
                      <div class="play-off" v-if="droneChannelInfo == null">
                        <img src="@/assets/images/play-off-icon.png" alt="icon" />
                        <h2>{{$t("monitoring-view-no-signal")}}</h2>
                      </div>
                    </div>
                    <div class="video-info">
                        <div class="video-top">
                            <!--종류에 따른 이미지 제어-->
                            <div class="emphysema-img">
                              <div class="drone-image" @click="moveMapCenter('Drone')">
                                <img  v-if="stationInfo != null && stationInfo.modelName == 'DJI Dock'" src="@/assets/images/drone_img/dji_matrice_300_RTK.png" alt="drone" />
                                <img  v-if="stationInfo != null && stationInfo.modelName == 'DJI Dock2'" src="@/assets/images/drone_img/dji_m3d.png" alt="drone" />
                              </div>
                            </div>
                            <div class="battery-info">
                                <h4>
                                    <span>{{storeDroneInfo == null || stationInfo == null || stationInfo.childStationDevice == null ? "0" : storeDroneInfo.battery.capacityPercent }}</span>
                                    <span>%</span>
                                </h4>
                                <ul>
                                  <li v-for="(b_index) in 5" :key="'battery' + b_index"  :class=" storeDroneInfo == null || (stationInfo != null && stationInfo.childStationDevice == null) ? 'off' : (5-b_index)*20 <=  storeDroneInfo.battery.capacityPercent ?  'on' : 'off'  " />
                                </ul>
                            </div>
                        </div>
                        <div class="drone-video-info">
                            <div class="video-title" style="position: relative;">
                                <button @click="playerReload(storeDroneInfo === null || stationInfo == null || stationInfo.childStationDevice == null ? null : stationInfo.childStationDevice.stationDeviceId, 'drone')" style="height: 28px;width: 40px;position: absolute;right: 0;border: 0;border-left: 1px solid #808080;"><img style="width: 13px;padding: 0 " src="@/assets/images/icon_svg/ic_refresh.png"></button>
                                <h2>
                                  <span>{{$t("monitoring-view-device")}}</span>
                                  <span>{{ storeDroneInfo == null || stationInfo == null || stationInfo.childStationDevice == null ? "-" : stationInfo.childStationDevice.deviceName }}</span>
                                </h2>
                                <h4>
                                  <span>{{$t("monitoring-view-user")}}</span>
                                  <span>{{ storeDroneInfo == null || stationInfo == null || stationInfo.childStationDevice == null ? "-" : stationInfo.childStationDevice.managerData.userName }}</span>
                                </h4>
                                <h4 >
                                  <span>{{$t("device-data-manager-dept-name")}} </span>
                                  <span style=" width: 50px;overflow: hidden;text-overflow: ellipsis; white-space: nowrap;">{{ storeDroneInfo == null || stationInfo == null || stationInfo.childStationDevice == null ? "-" : stationInfo.childStationDevice.managerData.userDepartmentName }}</span>
                                </h4>
                            </div>
                            <div class="video-value" style="font-size: 1.0rem;">
                                <div class="value-box">
                                    <h4>{{$t("monitoring-data-alt")}}</h4>
                                    <h2><span class="dataValue">{{ storeDroneInfo == null ? "-" : storeDroneInfo.elevation.toFixed(1) }}</span> m</h2>
                                </div>
                                <div class="value-box">
                                    <h4>{{$t("monitoring-data-dist")}}</h4>
                                    <h2><span class="dataValue">{{ storeDroneInfo == null ? "-" : storeDroneInfo.homeDistance.toFixed(1) }}</span> m</h2>
                                </div>
                                <div class="value-box">
                                    <h4>{{$t("monitoring-data-hs")}}</h4>
                                    <h2><span class="dataValue">{{ storeDroneInfo == null ? "-" : storeDroneInfo.horizontalSpeed.toFixed(1) }}</span> m/s</h2>
                                </div>
                                <div class="value-box">
                                    <h4>{{$t("monitoring-data-vs")}}</h4>
                                    <h2><span class="dataValue">{{ storeDroneInfo == null ? "-" : storeDroneInfo.verticalSpeed.toFixed(1) }}</span> m/s</h2>
                                </div>
                            </div>
                            <div class="video-coordinate">
                                <div class="coordinate-row">
                                    <h4>{{$t("monitoring-data-lat")}}</h4>
                                    <h2><span class="dataValue">{{ storeDroneInfo == null || storeDroneInfo.latitude == 0.0 ? "-" : storeDroneInfo.latitude.toFixed(6) }}</span></h2>
                                </div>
                                <div class="coordinate-row">
                                    <h4>{{$t("monitoring-data-long")}}</h4>
                                    <h2><span class="dataValue">{{ storeDroneInfo == null || storeDroneInfo.longitude == 0.0  ? "-" : storeDroneInfo.longitude.toFixed(6) }}</span></h2>
                                </div>
                                <div class="coordinate-row" style="width: 30%;">
                                  <h4>{{$t("drone-data-wind-speed")}}</h4>
                                  <h2><span> {{storeDroneInfo == null || storeDroneInfo == undefined ? '-' : (storeDroneInfo.windSpeed / 10).toFixed(1)}} </span> &nbsp; m/s <!-- 진행없으면 "-" --></h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 카메라 조작 -->
            <div class="con-camera">
                <div class="camera-btn">
                    <button
                    v-for="button in showButtons"
                    :key="button"
                    :class="{ active: cameraSelectButton === button }"
                    @click="cameraButton(button)"
                    >
                    {{ button }}
                    </button>
                </div>
                <div class="camera-set">
<!--                    <div class="set-box" v-if="showButtons.includes('WIDE')">-->
<!--                        <div class="csb-title">-->
<!--                            <h4>WIDE Focus Setting</h4>-->
<!--                            <div class="csb-select">-->
<!--                                <select v-model="wideFocusOptionSelect">-->
<!--                                    <option v-for="item in focusOptionList" :value="item.value" :key="'wide_focus_'+item.value">{{item.name}}</option>-->
<!--                                </select>-->
<!--                                <img src="@/assets/images/icon_svg/dropdown-menu-btn_w.png">-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="csb-remote">-->
<!--                            <div class="csb-form">-->
<!--                                <h4>Focus<span>{{ wideFocusValue }}</span></h4>-->
<!--                                <div class="csb-btn">-->
<!--                                    <button @click="increaseFocus('WIDE')" :disabled="wideFocusOptionSelect === 'MF'">+</button>-->
<!--                                    <button @click="decreaseFocus('WIDE')" :disabled="wideFocusOptionSelect === 'MF'">-</button>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="csb-input">-->
<!--                                <input :disabled="wideFocusOptionSelect === 'MF'"-->
<!--                                    type="range"-->
<!--                                    v-model="wideFocusValue"-->
<!--                                    :min="wideFocusMinValue"-->
<!--                                    :max="wideFocusMaxValue"-->
<!--                                    @input="wideUpdateFocus"-->
<!--                                >-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div> -->
                    <div class="set-box" v-if="showButtons.includes('ZOOM')">
                        <div class="csb-title">
                          <h4>ZOOM Focus Setting</h4>
                          <div class="csb-select">
                            <select @change="setZoomFocusOptionChange" >
                              <option v-for="item in focusOptionList" :value="item.value" :selected="zoomFocusOptionSelect === item.value" :key="'zoom_focus_'+item.value">{{item.name}}</option>
                            </select>
                            <img src="@/assets/images/icon_svg/dropdown-menu-btn_w.png">
                          </div>
                        </div>
                        <div class="csb-remote">
                          <div class="csb-form">
                            <h4>Focus<span>{{ zoomFocusValue }}</span></h4>
                            <div class="csb-btn">
                              <button @click="increaseFocus('ZOOM')" :disabled="zoomFocusOptionSelect !== 'MF'">+</button>
                              <button @click="decreaseFocus('ZOOM')" :disabled="zoomFocusOptionSelect !== 'MF'">-</button>
                            </div>
                          </div>
                          <div class="csb-input">
                            <input :disabled="zoomFocusOptionSelect !== 'MF'"
                                   type="range"
                                   v-model="zoomFocusValue"
                                   :min="zoomFocusMinValue"
                                   :max="zoomFocusMaxValue"
                                   @input="zoomUpdateFocus"
                            >
                          </div>
                        </div>
                        <div class="csb-title">
                            <h4>Zoom Setting</h4>
                        </div>
                        <div class="csb-remote">
                            <div class="csb-form">
                                <h4>Zoom<span>{{ zoomValue }}</span></h4>
                                <div class="csb-btn">
                                    <button @click="increaseZoom">+</button>
                                    <button @click="decreaseZoom">-</button>
                                </div>
                            </div>
                            <div class="csb-input">
                                <input
                                    type="range"
                                    v-model="zoomValue"
                                    min="2"
                                    :max="zoomMaxValue"
                                    @input="inputZoom"
                                    @change="updateZoom"
                                >
                            </div>
                        </div>
                    </div>
                    <div class="set-box" v-if="showButtons.includes('TH')">
                        <div class="csb-title">
                            <h4>TH Setting</h4>
                            <div class="csb-select">
                                <select v-model="thSelectOption" @change="updateImage">
                                    <option value="arctic">ARCTIC</option>
                                    <option value="black_hot">BLACK HOT</option>
                                    <option value="cold_spot">COLD SPOT</option>
                                    <option value="fulgurite">FULGURITE</option>
                                    <option value="gray">GRAY</option>
                                    <option value="hot_iron">HOT IRON</option>
                                    <option value="hot_metal">HOT METAL</option>
                                    <option value="hot_spot">HOT SPOT</option>
                                    <option value="iron_red">IRON RED</option>
                                    <option value="medical">MEDICAL</option>
                                    <option value="rainbow">RAINBOW</option>
                                    <option value="rainbow_1">RAINBOW 1</option>
                                    <option value="rainbow_2">RAINBOW 2</option>
                                    <option value="tint">TINT</option>
                                    <option value="white_hot">WHITE HOT</option>
                                </select>
                                <img src="@/assets/images/icon_svg/dropdown-menu-btn_w.png">
                            </div>
                        </div>
                        <div class="csb-remote">
                            <div class="csb-th-bar">
                                <img :src="imageSrc">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 스테이션 영상 -->
            <div class="con-station-view">
                <div class="station-live">
                      <div class="play-on" v-if="dockChannelInfo != null">
                        <WebRTC_WOWZA style="position: relative"
                                      class="play-on"
                                      v-if="dockChannelInfo != null"
                                      v-show="dockChannelInfo.isLoad == true"
                                      :sdpUrl="dockChannelInfo.streamServer.properties.sdpUrl"
                                      :applicationName=" (dockChannelInfo.assignedDeviceType === 'Drone' || dockChannelInfo.assignedDeviceType === 'StationDrone')? dockChannelInfo.streamServer.properties.droneApplication : dockChannelInfo.streamServer.properties.application"
                                      :streamName="dockChannelInfo.streamKey"
                                      :playerId="dockChannelInfo.channelId"
                                      :deviceType="dockChannelInfo.assignedDeviceType"
                                      :isShowLiveText = "false"
                                      :isSpeakerButtonShow = "false"
                                      :isModelButtonShow = "false"
                                      @isLoadedmetadata="playerLoadData"
                                      @playerRemoveId="playerRemoveId"
                        />

                        <!--                <videoPlayer :src="dockChannelManagerInfo.streamUrl" :isVod="false" :playerId="dockChannelManagerInfo.channelId" :isAuto ="true" :isDeleteButtonShow="false" :contentsId="null" :isControls="false"  />-->
                      </div>
                      <div class="play-off" v-if="dockChannelInfo == null" >
                        <img src="@/assets/images/play-off-icon.png" alt="icon" />
                        <h2>{{$t("monitoring-view-no-signal")}}</h2>
                      </div>
                    </div>
                    <div class="sv-con">
                        <div class="sv-img" @click="moveMapCenter('Dock')">
                            <img src="@/assets/images/drone_img/dock2.png" alt="station" />
                        </div>
                        <div class="sv-info">
                            <h4>1번 스테이션</h4>
                            <div class="sv-value">
                                <div class="sv-situation">
                                    <div class="sv-situation-row">
                                        <h4>{{$t("event-data-site-status")}}</h4>
                                        <h5>
                                            <span class="sv-mark" :class=" stationInfo == null || stationInfo.modeCode == null || stationInfo.modeCode == EDockModeCode.Disconnected ? 'sv-bad' : 'sv-good' "></span>
                                          <span> {{ stationInfo == null || stationInfo.modeCode == null ? EDockModeCode.getData(EDockModeCode.Disconnected).showName : EDockModeCode.getData(stationInfo.modeCode).showName  }}</span> <!-- 이상있으면 "이상감지" -->
                                        </h5>
                                    </div>
                                    <div class="sv-situation-row">
                                        <h4>{{$t("station-data-communication")}}</h4>
                                        <h5>
                                          <span> {{storeDockInfo == null || storeDockInfo.base == undefined ? '-' :  (storeDockInfo.base.networkState.rate / 1024).toFixed(2)}} </span> &nbsp; Mb/s <!-- 진행없으면 "-" -->
                                        </h5>
                                    </div>
                                </div>
                                <div class="sv-situation" style="font-size: 1.0rem;">
                                    <div class="sv-situation-row m-b-0">
                                        <h4>{{$t("drone-data-wind-speed")}}</h4>
                                        <h5>
                                          <span> {{storeDockInfo == null || storeDockInfo.base == undefined ? '-' : storeDockInfo.base.windSpeed.toFixed(1)}} </span> &nbsp; m/s <!-- 진행없으면 "-" -->
                                        </h5>
                                    </div>
                                    <div class="sv-situation-row m-b-0">
                                      <h4>{{$t("way-data-precipitation")}}</h4>
                                        <h5>
                                          <span> {{ storeDockInfo == null || storeDockInfo.base == undefined ? '-' : RainfallEnum.getData(storeDockInfo.base.rainfall).showName }}</span> <!-- 진행없으면 "-" -->
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </div>

    <!-- modal -->
    <div v-if="isModalVisible" class="modal">
        <div class="dock-modal-content">
                <div class="loader"></div>
                <h3>{{ modalMessage }}</h3>
        </div>
    </div>

    <div v-if="isCoordinateOpen" class="modal">
      <div class="cd-modal-content">
        <div class="cd-modal-title">
          <h2>{{$t("station-mode-modal-coordinate-title")}}</h2>
          <span class="close-btn" @click="closeCoordinateModal">&times;</span>
        </div>
        <div class="cd-modal-body" style="height: 200px;">
          <div class="cd-form">
            <label>{{$t("modal-setting-targetHeight")}}</label>
            <input type="text">
          </div>
          <div class="cd-form">
            <label>{{$t("mission-data-lat")}}</label>
            <input type="text" value="123.5469854" disabled>
          </div>
          <div class="cd-form">
            <label>{{$t("mission-data-long")}}</label>
            <input type="text" value="23.5469854" disabled>
          </div>
        </div>
        <div class="cd-modal-btn">
          <button class="submit-btn">{{$t("btn-confirm")}}</button>
          <button class="close-btn" @click="closeCoordinateModal">{{$t("btn-cancel")}}</button>
        </div>
      </div>
    </div>

    <div v-if="isDroneTakeoffOpen" class="modal">
        <div class="cd-modal-content">
            <div class="cd-modal-title">
                <h2>{{$t("station-mode-modal-takeoff-title")}}</h2>
                <span class="close-btn" @click="closeDroneTakeoffModal">&times;</span>
            </div>
            <div class="cd-modal-body">
                <div class="cd-form">
                    <label>{{$t("mission-data-lat")}}({{$t("station-mode-modal-dock-location")}})</label>
                    <input type="text" :value="storeDockInfo === null || storeDockInfo.base === undefined ? '-' : storeDockInfo.base.latitude " disabled>
                </div>
                <div class="cd-form">
                    <label>{{$t("mission-data-long")}}({{$t("station-mode-modal-dock-location")}})</label>
                    <input type="text" :value="storeDockInfo === null || storeDockInfo.base === undefined ? '-' : storeDockInfo.base.longitude " disabled>
                </div>
                <div class="cd-form">
                    <label>{{$t("modal-setting-targetHeight")}}</label>
                    <input type="text" :value="droneTakeoffHeight" >
                </div>
                <div class="cd-form">
                    <label>{{$t("modal-setting-securityTakeoffHeight")}}</label>
                    <input type="text" :value="droneTakeoffDockSecurityHeight">
                </div>
                <div class="cd-form">
                    <label>{{$t("modal-setting-rthAltitude")}}</label>
                    <input type="text" :value="droneTakeOffRTLHeight">
                </div>
                <div class="cd-form">
                    <label>{{$t("modal-setting-rcLostAction")}}</label>
                    <select v-model="droneTakeoffRCLostAction">
                        <option value="HOVER">{{$t("event-data-status-ready")}}</option>
                        <option value="LAND">{{$t("station-mode-modal-select-option-landing")}}</option>
                        <option value="RETURN_HOME">RTL</option>
                    </select>
                    <img src="@/assets/images/icon_svg/dropdown-menu-btn_w.png">
                </div>
                <div class="cd-form">
                    <label>{{$t("modal-setting-maxSpeed")}}(2~15m/s)</label>
                    <input type="text" v-model="droneTakeOffSpeed" placeholder="2~15m/s" max="15" min="2" @change="validateSpeed">
                </div>
                <div class="cd-form" v-show="false">
                    <label>{{$t("modal-setting-rthMode")}}</label>
                    <select v-model="droneTakeoffRtlMode">
                        <option value="OPTIMAL_HEIGHT">{{$t("station-mode-modal-select-optimal-height")}}</option>
                        <option value="PRESET_HEIGHT">{{$t("station-mode-modal-select-preset-height")}}</option>
                    </select>
                    <img src="@/assets/images/icon_svg/dropdown-menu-btn_w.png">
                </div>
                <div class="cd-form" v-show="false">
                    <label>{{$t("modal-setting-commanderModeLostAction")}}</label>
                    <select v-model="droneTakeoffCommanderModeLostAction">
                        <option value="EXECUTE_RC_LOST_ACTION">RCLostAction</option>
                        <option value="CONTINUE">{{$t("station-mode-modal-select-continue")}}</option>
                    </select>
                    <img src="@/assets/images/icon_svg/dropdown-menu-btn_w.png">
                </div>
                <div class="cd-form" v-show="false">
                    <label>{{$t("modal-setting-commanderFlightMode")}}</label>
                    <select v-model="droneTakeoffCommanderFlightMode">
                        <option value="SMART_HEIGHT">{{$t("station-mode-modal-select-optimal-height")}}</option>
                        <option value="SETTING_HEIGHT">{{$t("station-mode-modal-select-preset-height")}}</option>
                    </select>
                    <img src="@/assets/images/icon_svg/dropdown-menu-btn_w.png">
                </div>
                <div class="cd-form" v-show="false">
                    <label>{{$t("modal-setting-commanderFlightHeight")}}</label>
                    <input v-model="droneTakeoffCommanderFlightHeight" type="text">
                </div>
            </div>
            <div class="cd-modal-btn">
                <button class="submit-btn" @click="confirmFlightStart" >{{$t("btn-confirm")}}</button>
                <button class="close-btn" @click="closeDroneTakeoffModal">{{$t("btn-cancel")}}</button>
            </div>
        </div>
    </div>

    <div v-if="isRTHModalOpen" class="modal" @click.self="closeRTHModal">
      <div class="rth-modal-content">
        <p>{{$t("station-mode-rtk-completion")}}</p>
        <button class="close-btn" @click="closeRTHModal">{{$t("btn-confirm")}}</button>
      </div>
    </div>
    <div v-if="isRTHStopModalOpen" class="modal" @click.self="closeRTHStopModal">
      <div class="rth-modal-content">
        <p>{{$t("station-mode-rtk-interrupted")}}</p>
        <button class="close-btn" @click="closeRTHStopModal">{{$t("btn-confirm")}}</button>
      </div>
    </div>
  </div>
</template>
<style>
.m-b-10{margin-bottom: 10px;}
body{background: #101010;}

.remote-station-title{background: #101010; padding: 5px 40px; border-radius: 5px; position: absolute; top: 10px; left: 50%;transform: translate(-50%, 0%); z-index: 100;}
.remote-station-title > h3{font-size: 2rem;font-weight: bold;color: #fff;}

.con-drone-set > button{width: 100%; border: 0;padding: 0px 20px;background: #3c3c3c;border-radius: 5px;color: #fff;font-size: 1.4rem;height: 40px; margin-bottom: 10px;}
.con-drone-set > button > img{width: 16px;margin-right: 10px;}

.con-body{display: flex;justify-content: space-between;align-items: stretch;margin-top: 15px;}

.con-drone-view{position: relative; width: calc(100% - 390px);height: calc(100vh - 30px);;background: #232323;}
.con-drone-set{width: 375px;}

.live-mark{position: absolute;background: #f42525;padding: 5px 20px;border-radius: 5px;color: #fff;font-weight: bold;top: 20px;left: 20px;}
.camera-value{position: absolute;top: 20px;left: 50%;transform: translate(-50%, 0%);background: #1010108a;padding: 10px 20px;color: #fff;font-size: 1.4rem;border-radius: 5px;}
.camera-value > span{margin: 0 5px;}

.sdr-con{position: absolute;z-index: 100;bottom: 20px;left: 50%;transform: translate(-50%, 0%);display: flex;justify-content: space-between;align-items: center;}

.con-live-view{width: 100%;height: 100%;}
/* remote btn */
.sr-con{width: 260px;height: 290px;margin-right: 15px;}
.sr-con > .sr-btn{background: #101010cc;padding: 15px;border-radius: 5px;}
.sr-con > .sr-btn > .sr-btn-title{display: flex;justify-content: space-between;align-items: center;margin-bottom: 15px;}
.sr-con > .sr-btn > .sr-btn-title > h2{font-size: 1.4rem;color: #fff;margin: 0;}
.sr-con > .sr-btn > .sr-btn-title > button{background: #0080ff;border: 0;border-radius: 5px;padding: 0 15px;color: #fff;height: 30px;font-size: 1.4rem;}

.sr-con > .sr-btn > .sr-btn-list > ul{display: flex;justify-content: space-evenly;align-items: center;background: #232323;padding: 14px 0;border-radius: 5px;}
.sr-con > .sr-btn > .sr-btn-list > ul > li{display: flex;flex-direction: column;align-items: center;}
.sr-con > .sr-btn > .sr-btn-list > ul > li > .sr-btn-box > .sr-btn-icon{width: 50px;height: 50px;background: #101010;border-radius: 50px;display: flex;align-items: center;justify-content: center;margin-bottom: 10px;}
.sr-con > .sr-btn > .sr-btn-list > ul > li > .sr-btn-box > .sr-btn-icon:hover{background: #0080ff;cursor: pointer;}
.sr-con > .sr-btn > .sr-btn-list > ul > li > .sr-btn-box > .sr-btn-icon > img{width: 18px;}
.sr-con > .sr-btn > .sr-btn-list > ul > li > .sr-btn-box > h4{font-size: 1.2rem;color: #fff;font-weight: 300;}

.sr-btn-false{width: 230px;height: 100px; margin-bottom: 12px; background: #232323;display: flex;justify-content: center;align-items: center;text-align: center;}
.sr-btn-false > h4{font-size: 1.4rem;font-weight: 300;color: #c9c9c9;}

.dr-con{width: auto;height: 290px;margin-right: 15px;}
.dr-btn{background: #101010cc;padding: 5px 15px 15px 15px;border-radius: 5px;}

.dr-btn-title{display: flex;justify-content: space-between;align-items: center;margin-bottom: 15px;}
.dr-btn-title.add-btn-title {display: flex;justify-content: space-between; align-items: center; margin-top: 4px; margin-bottom: 4px; padding-bottom: 0px;}
.dr-btn-title > h2{font-size: 1.4rem;color: #fff;margin: 0;}

.dr-btn-title > .sr-btn-box > .sr-btn-icon{width: 50px;height: 50px;background: #101010;border-radius: 50px;display: flex;align-items: center;justify-content: center;margin-bottom: 10px;}
.dr-btn-title > .sr-btn-box > .sr-btn-icon.add-btn-title {width: 70px; height: 36px; background: #000000; border-radius: 9px;display: flex;align-items: center;justify-content: center; margin-bottom : 0px; }
.dr-btn-title > .sr-btn-box > .sr-btn-icon:hover{background: #0080ff;cursor: pointer;}
.dr-btn-title > .sr-btn-box > .sr-btn-icon > img{width: 18px;}


.dr-btn-form{display: flex;justify-content: space-between;align-items: center;height: 222px;}
.dr-btn-form > .lf-btn{display: flex;flex-direction: column;align-items: flex-end;margin-right: 30px;}
.dr-btn-form > .lf-btn > .altitude-value{margin-bottom: 15px;display: flex;flex-direction: column;align-items: flex-end;}
.dr-btn-form > .lf-btn > .altitude-value > span{font-size: 1.4rem;font-weight: 300;color: #3dffab;}
.dr-btn-form > .lf-btn > .altitude-value > h4{font-size: 1.4rem;color: #3dffab;}
.dr-btn-form > .lf-btn > .altitude-value > h4 > span{font-size: 2rem;margin-right: 5px;}
.dr-btn-form > .lf-btn > .at-key{display: flex;flex-direction: column;flex-wrap: wrap;}
.dr-btn-form > .lf-btn > .at-key > .at-key-row{display: flex;align-items: center;}

.can-btn{display: flex;align-items: center;margin-right: 30px;}
.at-img{margin-right: 10px;}
.angle-info{position: relative;}
.angle-info > h4{position: absolute;left: 52%;transform: translate(-50%, 0%);top: -30px;color: #3df076;font-size: 2rem;}
.angle-info > h4 > span{font-size: 2.2rem;}
.angle-img{position: relative;width: 150px;height: 150px;}
.angle-img > .angle_img{width: 100%;left: 50%;top: 0%;transform: translate(-50%, -50%);position: absolute;}
.angle-img > .droneMark_img{position: absolute;width: 35%;top: 10%;left: 50%;transform: translate(-50%, 50%);}
.angle-img > .lm_img{position: absolute;width: 10px;top: 20px;left: 50%;transform: translate(-50%, 50%);}

.op-btn{display: flex;align-items: center;}
.op-btn > button{margin: 0 3px;width: 38px;height: 38px;border: 0;background: #232323;display: flex;justify-content: center;align-items: center;border-radius: 3px;}
/* .op-btn > button:hover{background: #0080ff;} */
.op-btn > button.active{background: #0080ff;}
.op-btn > button > img{width: 18px;}
.dt-key{margin-top: 20px;}
.dt-key-row{display: flex;justify-content: center;align-items: center;}

.dr-btn-false{width: 515px;height: 222px;background: #232323;display: flex;justify-content: center;align-items: center;text-align: center;}
.dr-btn-false > h4{font-size: 1.4rem;font-weight: 300;color: #c9c9c9;}

.cr-con{width: 250px;height: 290px;}
.cr-btn{background: #101010cc;padding: 15px;border-radius: 5px;}
.cr-btn-title{display: flex;justify-content: space-between;align-items: center;margin-bottom: 15px;}
.cr-btn-title > h2{font-size: 1.4rem;color: #fff;margin: 0;}
.cr-radar{display: flex;justify-content: center;margin-bottom: 13px;padding-top: 5px;position: relative;}
.cr-radar-img{width: 130px;height: 130px;background: #000;border-radius: 100px;position: relative;border: 1px solid #ddd;}
.cr-radar-img::before{content: '';position: absolute;border: 0.5px solid #ddd;width: 100%;top: 50%;left: 50%;transform: translate(-50%, -50%);}
.cr-radar-img::after{content: '';height: 100%;width: 1px;position: absolute;background: #ddd;top: 50%;left: 50%;transform: translate(-50%, -50%);}
.cr-radar-axis{position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);width: 10px;height: 10px;z-index: 102;}
.cr-radar-axis > img{position: absolute;width: 45px;transition: 1s all;transform: translate(-50%, -100%);top: 50%;left: 50%;}
.cr-key{display: flex;justify-content: space-between;align-items: center;}

.cr-btn-false{width: 220px;height: 220px;background: #232323;display: flex;justify-content: center;align-items: center;text-align: center;}
.cr-btn-false > h4{font-size: 1.4rem;font-weight: 300;color: #c9c9c9;}

.cr-radar-date{position: absolute;top: 0;right: 0;}
.cr-radar-date > h4{font-size: 1.2rem;color: #fff;font-weight: 300;margin-bottom: 10px;display: flex;justify-content: space-between;align-items: center;width: auto;}
.cr-radar-date > h4 > img{margin-right: 5px;}
.cr-radar-date > h4 > span{font-size: 1.2rem;color: #fff;font-weight: 300;}

.key-btn{background: #232323;display: flex;flex-direction: column;align-items: center;padding: 5px;margin: 3px;border-radius: 3px; height: auto;border: 0;}
.key-img{width: 15px;height: 15px; margin-bottom: 5px;padding-top: 2px;text-align: center;}
.key-img > img{width: 10px;}
.key-btn > h6{background: #101010;font-size: 1.6rem;width: 33px;height: 27px;text-align: center;border-radius: 3px;display: flex;justify-content: center;align-items: center;color: #fff;}
.key-btn.active {background:#0080ff;}
.key-btn.active > h6 {background: #0249cb;}

.flashing {
  animation: flash 1s infinite;
}

@keyframes flash {
  0% {
    background-color: #101010;
  }
  50% {
    background-color: #f42525;
  }
  100% {
    background-color: #101010;
  }
}

/* drone css */
.con-live-box{width: 375px;height: 298px;background: #232323;margin-bottom: 15px;}
.vodview{position: relative; display: flex;height: 100%;background: #232323;margin-bottom: 15px;flex-direction: column;}
.switching-btn{position: absolute; right: 0;top:0;z-index: 100;background: #101010cc;}
.video-box{width: 100%;height: 100%;background: #000000;position: relative;}
.video-box > .play-on{position: relative; width: 100%;height: 100%;}
.video-box > .play-off{width: 100%;text-align: center;position:absolute;transform: translate(-50%, -50%);top: 50%;left: 50%;}
.video-box > .play-off > h2{font-size: 1.2rem;color: #fff;margin-top: 10px;}
.video-box > .play-off > img{width: 25px;}

.video-box > .play-off-4{width: 100%;text-align: center;padding-top: 56.25%;}
.video-box > .play-off-4 > h2{font-size: 1.2rem;color: #fff;margin-top: 10px;position:absolute;transform: translate(-50%, -50%);top: 52%;left: 50%;}
.video-box > .play-off-4 > img{width: 25px;position:absolute;transform: translate(-50%, -50%);top: 48%;left: 50%;}

.video-box > .play-off-9{width: 100%;text-align: center;padding-top: 56.25%;}
.video-box > .play-off-9 > h2{font-size: 1.2rem;color: #fff;margin-top: 10px;position:absolute;transform: translate(-50%, -50%);top: 52%;left: 50%;}
.video-box > .play-off-9 > img{width: 25px;position:absolute;transform: translate(-50%, -50%);top: 48%;left: 50%;}

.video-info{width:100%;color: #fff;padding: 0px;display: flex;flex-direction: row;justify-content: space-between;border-top: 1px solid #4f4f4f;}
.video-info > .video-top{display: flex;align-items: center;justify-content: space-between; margin-bottom: 0px;background: #232323;flex-direction: column;width: 80px;border-right: 1px solid #4f4f4f;}
.video-info > .video-top > .emphysema-img{width: 70px;background: #232323;border-radius: 5px;}
.video-info > .video-top > .emphysema-img > .drone-image{width: 100%;height: 50px;background: #232323;border-radius: 5px;padding: 0px;text-align: center;display: flex;align-items: center;}
.video-info > .video-top > .emphysema-img > .phone-image{width: 100%;height: 50px;background: #232323;border-radius: 5px;padding: 5px;text-align: center;}
.video-info > .video-top > .emphysema-img > .drone-image > img{width: 100%;}
.video-info > .video-top > .emphysema-img > .phone-image > img{height: 100%;}

.video-info > .video-top > .battery-info{display: flex;align-items: center;margin-bottom: 5px;}
.video-info > .video-top > .battery-info > ul{display: flex;margin-bottom: 0px;}
.video-info > .video-top > .battery-info > ul > li{height: 18px;width: 5px;background: #878787;margin-right: 2px;border-radius: 2px;}
.video-info > .video-top > .battery-info > ul > li.on{background: #1cdfa0;}
.video-info > .video-top > .battery-info > ul > li.off{background: #878787}
.video-info > .video-top > .battery-info > h4{text-align: right;margin-right: 5px;}
.video-info > .video-top > .battery-info > h4 > span{font-size: 1.1rem;font-weight: 400;}

.video-info > .drone-video-info{width: calc(100% - 80px);}

.video-info > .drone-video-info > .video-title{border-bottom: 1px solid #4f4f4f;padding: 7px 0px 7px 10px;display: flex;align-items: center;}
.video-info > .drone-video-info > .video-title > h2{font-size: 1.1rem;margin-bottom: 0px;letter-spacing: -1px; margin-right: 10px;}
.video-info > .drone-video-info > .video-title > h2 > span{font-size: 1.1rem;margin-bottom: 0px;letter-spacing: -1px;}
.video-info > .drone-video-info > .video-title > h2 > span:nth-child(1){color: #c9c9c9;margin-right: 5px;}
.video-info > .drone-video-info > .video-title > h4{font-size: 1.1rem;font-weight: 400;margin-bottom: 0px;display: flex; margin-right: 10px;}
.video-info > .drone-video-info > .video-title > h4:nth-child(3){margin-bottom: 0;}
.video-info > .drone-video-info > .video-title > h4 > span{font-size: 1.1rem;}
.video-info > .drone-video-info > .video-title > h4 > span:nth-child(1){color: #c9c9c9;margin-right: 5px;}

.video-info > .drone-video-info > .video-value{display: flex;flex-wrap: wrap;align-items: center;padding-top: 7px;padding-left: 10px;margin-bottom: 6px;}
.video-info > .drone-video-info > .video-value > .value-box{width: 25%;display: flex;align-items: center;}
.video-info > .drone-video-info > .video-value > .value-box > h4{font-size: 1rem;color: #c9c9c9;margin-bottom: 0px;margin-right: 5px;font-weight: 400;}
.video-info > .drone-video-info > .video-value > .value-box > h4 > span{font-size: 1rem;}
.video-info > .drone-video-info > .video-value > .value-box > h2{font-size: 1rem;font-weight: 300;margin-bottom: 0px}
.video-info > .drone-video-info > .video-value > .value-box > h2 > span{font-size: 1rem;}

.video-info > .drone-video-info > .video-coordinate{padding-left: 10px;display: flex;justify-content: space-between;align-items: center;padding-bottom: 2px;}
.video-info > .drone-video-info > .video-coordinate > .coordinate-row{display: flex;align-items: center;margin-bottom: 0px;width: 50%;}
.video-info > .drone-video-info > .video-coordinate > .coordinate-row > h4{font-size: 1rem;color: #c9c9c9;margin-right: 5px; font-weight: 400;}
.video-info > .drone-video-info > .video-coordinate > .coordinate-row > h2{margin: 0;font-weight: 300;font-size: 1rem;}
.video-info > .drone-video-info > .video-coordinate > .coordinate-row > h2 > span{font-size: 1rem;}


/* camera css */
.con-camera{width: 375px;height: 273px;background: #232323;border-radius: 5px;margin-bottom: 15px;padding: 10px;}
.camera-btn{display: flex; margin-bottom: 10px;}
.camera-btn > button{width: 70px;border: 0;margin-right: 5px;border-radius: 3px;color: #c9c9c9;}
.camera-btn > button.active{background: #0080ff;color: #fff;}
.camera-set{height: 210px;max-height: 210px;overflow-y: auto;}
.camera-set::-webkit-scrollbar{width: 8px;}
.camera-set::-webkit-scrollbar-thumb{background-color: #707070;border-radius: 4px;}
.camera-set::-webkit-scrollbar-track{border-radius: 2px;background: #1b1b1b;border: 1px solid #1b1b1b;}
.camera-set > .set-box{background: #3c3c3c;padding: 10px;border-radius: 5px;margin-bottom: 10px;}
.csb-title{display: flex;justify-content: space-between;align-items: center;margin-bottom: 10px;}
.csb-title > h4{font-size: 1.4rem;color: #fff;font-weight: 300;}
.csb-title > .csb-select{position: relative;}
.csb-title > .csb-select > select{background: none;color: #fff;font-size: 1.2rem;width: auto;border: 0;}
.csb-title > .csb-select > select > option{background: #232323;color: #c9c9c9;}
.csb-title > .csb-select > img{position: absolute;top: 13px;right: 5px;}
.csb-remote > .csb-form{display: flex;justify-content: space-between;align-items: center;}
.csb-remote > .csb-form > h4{font-size: 1.2rem;color: #c9c9c9;font-weight: 300;}
.csb-remote > .csb-form > h4 > span{font-size: 1.4rem;color: #fff;margin-left: 10px;}
.csb-remote > .csb-form > .csb-btn > button{width: 30px;height: 30px;color: #fff;font-size: 2rem;background: #101010;border: 0;margin-left: 5px;border-radius: 5px;}
.csb-remote > .csb-input{width: 100%;margin-top: 10px;margin-bottom: 10px;}
.csb-remote > .csb-input > input{width: 100%;}
.csb-remote > .csb-th-bar{width: 100%;margin: 15px 0 15px 0;}
.csb-remote > .csb-th-bar > img{width: 100%;height: 25px;}


/* station */
/* 290px -> 301px */
.con-station-view{width: 375px;height: 293px;background: #232323;}

/* 200px -> 211px */
.station-live{width: 100%;height: 211px;background: #000000;position: relative;}
.station-live > .play-on{width: 100%;height: 100%;}
.station-live > .play-off{width: 100%;text-align: center;position:absolute;transform: translate(-50%, -50%);top: 50%;left: 50%;}
.station-live > .play-off > img{width: 25px;}
.station-live > .play-off > h2{font-size: 1.2rem;color: #fff;margin-top: 10px;}

.sv-con{width: 100%; height: 82px;color: #fff;padding: 0px;display: flex;flex-direction: row;justify-content: space-between;border-top: 1px solid #4f4f4f;}
.sv-con > .sv-img{width: 80px;border-right: 1px solid #4f4f4f;display: flex;align-items: center;justify-content: center;}
.sv-con > .sv-img > img{width: 50px;}
.sv-con > .sv-info{width: calc(100% - 80px);}
.sv-con > .sv-info > h4{font-size: 1.4rem; font-weight: 300;padding: 5px 0 5px 10px;margin-bottom: 8px;}
.sv-con > .sv-info > .sv-value{display: flex;justify-content: space-between;flex-direction: column;}
.sv-con > .sv-info > .sv-value > .sv-situation{display: flex;justify-content: space-between;align-items: center;width: 100%;padding: 0 10px;}
.sv-con > .sv-info > .sv-value > .sv-situation > .sv-situation-row{margin-bottom: 8px;display: flex;justify-content: space-between;align-items: center;width: 48%;}
.sv-con > .sv-info > .sv-value > .sv-situation > .sv-situation-row > h4{font-size: 1rem;font-weight: 300;color: #c9c9c9;}
.sv-con > .sv-info > .sv-value > .sv-situation > .sv-situation-row > h5{display: flex;justify-content: space-between;align-items: center;font-size: 1rem;font-weight: 300;}
.sv-con > .sv-info > .sv-value > .sv-situation > .sv-situation-row > h5 > .sv-mark{display: inline-block; width: 10px;height: 10px;background: #ddd;border-radius: 10px;margin-right: 5px;}
.sv-con > .sv-info > .sv-value > .sv-situation > .sv-situation-row > h5 > .sv-mark.sv-good{background: #1cdfa0;}
.sv-con > .sv-info > .sv-value > .sv-situation > .sv-situation-row > h5 > .sv-mark.sv-bad{background: #ff3d3d;}
.sv-con > .sv-info > .sv-value > .sv-situation > .sv-situation-row > h5 > span{font-size: 1rem;font-weight: 300;}

/* modal */
.modal {position: fixed;width: 100%;height: 100%;background-color: rgba(0, 0, 0, 0.5); /* 반투명 배경 */display: flex;justify-content: center;align-items: center;}
.dock-modal-content {display: flex; flex-direction: column;align-items: center;justify-content: space-between;width: 400px; height: 150px; background: #232323;padding: 30px;border-radius: 8px;text-align: center; color: #fff;}
.dock-modal-content > h3{font-size: 1.4rem; font-weight: 400;}
.loader {width: 120px;height: 20px;-webkit-mask:linear-gradient(90deg,#000 70%,#0000 0) 0/20%;background:linear-gradient(#1cdfa0 0 0) 0/0% no-repeat#ddd;animation: l4 2s infinite steps(6);}
@keyframes l4 {100% {background-size:120%}}

.cd-modal-content{width: 800px; height: auto; background: #232323;padding: 30px;border-radius: 5px;color: #fff;}
.cd-modal-title{display: flex;justify-content: space-between;align-items: center;margin-bottom: 20px;padding-bottom: 10px;border-bottom: 1px solid #707070;}
.cd-modal-title > h2{margin: 0;font-size: 1.6rem;}
.cd-modal-title > span{font-size: 2rem;}
.cd-modal-body{margin-bottom: 20px;padding-bottom: 10px;border-bottom: 1px solid #707070;height: 450px;max-height: 450px;overflow-y: auto;padding-right: 10px;}
.cd-modal-body::-webkit-scrollbar{width: 8px;}
.cd-modal-body::-webkit-scrollbar-thumb{background-color: #707070;border-radius: 4px;}
.cd-modal-body::-webkit-scrollbar-track{border-radius: 2px;background: #1b1b1b;border: 1px solid #1b1b1b;}
.cd-form{position: relative; display: flex; justify-content: flex-start; align-items: center;margin-bottom: 15px;}
.cd-form > label{font-size: 1.4rem;width: 30%; color: #c9c9c9;}
.cd-form > input{margin-bottom: 0;width: 70%; height: 40px;color: #fff;border: 1px solid #707070;border-radius: 5px;font-size: 1.4rem;}
.cd-form > input:disabled{background: #101010;color: #969696;}
.cd-form > select{margin-bottom: 0;width: 70%;height: 40px;background: none;color: #fff;border: 1px solid #707070;border-radius: 5px;font-size: 1.4rem;font-weight: 300;}
.cd-form > select > option{background: #232323;color: #c9c9c9;font-size: 1.4rem;}
.cd-form > img{position: absolute;top: 17px;right: 10px;}
.cd-modal-btn{display: flex;justify-content: center;align-items: center;padding-top: 10px;}
.cd-modal-btn > .submit-btn{font-size: 1.4rem;background: #0080ff;color: #fff;border-radius: 5px;border: 0;width: 120px;margin: 0 5px;}
.cd-modal-btn > .close-btn{font-size: 1.4rem;background: #3c3c3c;color: #fff;border-radius: 5px;border: 0;width: 120px;margin: 0 5px;}

.rth-modal-content{display: flex; flex-direction: column;align-items: center;justify-content: space-between;width: 400px; height: 150px; background: #232323;padding: 30px;border-radius: 8px;text-align: center; color: #fff;}
.rth-modal-content > p{font-size: 1.4rem;}
.rth-modal-content > .close-btn{font-size: 1.4rem;background: #0080ff;color: #fff;border-radius: 5px;border: 0;width: 120px;margin: 0 5px;}


/* add css */
.angle-select{position: relative;width: auto;height: 30px;background: #101010;border: 1px solid #707070;}
.angle-select > select{width: auto;color: #fff;height: 100%;background: #101010;border: 0;}
.angle-select > select > option{background: #101010;}
.angle-select > img{position: absolute;right: 5px;top: 11px;}
.cr-angle-value{position: absolute;top: 0;left: 0;display: flex;align-items: center;}
.cr-angle-value > img{margin-right: 8px;}
.cr-angle-value > h4{font-size: 1.4rem;color: #fff;font-weight: 500;}
.cr-angle-value > h4 > span{margin-right: 3px;}

.camera-toggle{position: absolute;top: 50%;right: 0;transform: translate(-15%, -50%);background: #232323cc;padding: 20px 10px;border-radius: 5px; z-index: 100;}
.camera-switching-btn{display: flex;flex-direction: column;}
.camera-switching-btn > button{border: 0;margin-bottom: 15px;}
.camera-switching-btn > button > img{width: 30px;opacity: 0.5;}
.camera-switching-btn > button.active > img{width: 30px;opacity: 1;}
.shot-content{margin-top: 30px;}
.shot-content > .camera-shot > .camera-shot-btn{position: relative; width: 26px;height: 26px;background: #fff;border-radius: 50px;cursor: pointer;margin: auto;margin-bottom: 10px;}
.shot-content > .camera-shot > .camera-shot-btn:hover{background: #ffffffcc;}
.shot-content > .camera-shot > .camera-shot-btn::before{content: '';position: absolute;width: 35px;height: 35px;background: #fff0;border: 2px solid #ddd;border-radius: 50px;top: 50%;left: 50%;transform: translate(-50%, -50%);}
.shot-content > .camera-shot > h4{font-size: 1.4rem;color: #fff;font-weight: 500;text-align: center;}
.shot-content > .video-shot > .video-shot-btn{position: relative; width: 26px;height: 26px;background: #ff5151;border-radius: 50px;cursor: pointer;margin: auto;margin-bottom: 10px;transition: border-radius 0.7s ease-in-out;}
.shot-content > .video-shot > .video-shot-btn.recording{border-radius: 5px;width: 32px;height: 32px;background: #ffffff;}
.shot-content > .video-shot > .video-shot-btn::before{content: '';position: absolute;width: 35px;height: 35px;background: #fff0;border: 2px solid #ff5151;border-radius: 50px;top: 50%;left: 50%;transform: translate(-50%, -50%);transition: 0.7s ease-in-out;}
.shot-content > .video-shot > .video-shot-btn.recording::before{content: '';position: absolute;width: 0px;height: 0px;background: #fff0;border: 0px solid #ff5151;border-radius: 50px;top: 50%;left: 50%;transform: translate(-50%, -50%);}
.shot-content > .video-shot > h4{font-size: 1.4rem;color: #fff;font-weight: 500;text-align: center;}

.camera-massage{position: absolute;top: 40px;left: 50%;transform: translate(-50%, -15%);background: #232323cc;padding: 10px 20px;border-radius: 5px; z-index: 100;animation: cameraMessage 2s ease-in-out;opacity: 0;}
.camera-massage > h4{color: #fff;font-size: 1.4rem;font-weight: 400;}
.video-massage{position: absolute;top: 40px;left: 50%;transform: translate(-50%, -15%);background: #232323cc;padding: 10px 20px;border-radius: 5px; z-index: 100;animation: videoMessage 2s ease-in-out;opacity: 0;}
.video-massage > h4{color: #fff;font-size: 1.4rem;font-weight: 400;}
@keyframes cameraMessage {
  0% {
    top: 40px;
    opacity: 0;
  }
  25%{
    top: 70px;
    opacity: 1;
  }
  75%{
    top: 70px;
    opacity: 1;
  }
  100% {
    top: 40px;
    opacity: 0;
  }
}
@keyframes videoMessage {
  0% {
    top: 40px;
    opacity: 0;
  }
  25%{
    top: 70px;
    opacity: 1;
  }
  75%{
    top: 70px;
    opacity: 1;
  }
  100% {
    top: 40px;
    opacity: 0;
  }
}
</style>
<script>
import moment from "moment";
import Map from "@/components/map/Map";
import MapButtonComponent from "@/components/map/MapButtonComponent.vue";

import {stationPopup} from "@/util/popup";
import {ko} from "vuejs-datepicker/dist/locale";

import {mapState} from "vuex";

import MapDataType from "@/components/map/enum/MapDataType";
import MapMarkerData from "@/components/map/data/MapMarkerData";
import MapLineData from "@/components/map/data/MapLineData";
import MapPolygonData from "@/components/map/data/MapPolygonData";
import MapMappingData from "@/components/map/data/MapMappingData";

import {getDefaultMapZoomLevel} from "@/util/common";

import { fetchEvent} from "@/api/event";
import { fetchPointList, getPointTypeName } from "@/api/point";
import { fetchLocation } from "@/api/location";

//station
import {fetchStationDeviceInfo, fetchStationDevicePayloadInfo} from "@/api/station/stationDevice";
import {fetchStationMissionJob, fetchStationMissionJobStatus} from "@/api/station/stationMissionJob";
import {fetchStationDeviceChannelManagerInfo} from "@/api/station/stationDeviceChannelManager";
import { fetchStationDrcEnter, fetchStationDrcExit } from '@/api/station/stationDrc'
import { fetchStationDeviceDockAuthorityFlight, fetchStationDeviceDockDebug,
  fetchStationDeviceDockAuthorityPayload,
  fetchStationDeviceDockPayloadCommands,
  PayloadCommandsEnum,
  fetchStationDeviceDockJobTakeoffToPoint
} from "@/api/station/stationDock";
import { EDockModeCode, EModeCode, RainfallEnum } from '@/station/type/device'
import { ControlSource } from '@/station/type/device'
import {fetchStationLiveStreamCapacityList, fetchStationLiveStreamSwitch} from "@/api/station/stationLiveStream";
import {fetchMissionInfo, getMissionCameraList } from "@/api/mission";
import {fetchMissionLocationList } from "@/api/missionLocation";
import { fetchStationMissionPause, fetchStationMissionResume } from "@/api/station/stationMission";
import MapUtils from "@/components/common/MapUtils";

//websocket
import UseConnectWebSocket from "@/station/websocket/use-connect-websocket";
import { messageHandler } from "@/station/websocket/message-handler-websocket";
import UseDroneControlWsEvent from "@/station/dji/control/use-drone-control-ws-event";

import WebRTC_WOWZA from "@/components/video/WebRTC_WOWZA";
import { fetchChannel } from "@/api/channel";

//mqtt
import UseConnectMqtt from "@/station/dji/control/use-mqtt-connect.js";
import UseMqtt from '@/station/dji/control/use-mqtt.js';
import UseDroneControlMqttEvent from "@/station/dji/control/use-drone-control-mqtt-event";
import {ControlKeyCode, UseManualControl} from "@/station/dji/control/use-manual-control";
import {GimbalKeyCode} from "@/station/type/drone-control";
import {fetchChannelCheckStream} from "../../api/channel";
import {stationLicenceCheck} from "@/util/Licence";

UseManualControl;
stationPopup
UseDroneControlWsEvent;
export default {
    components: {
      Map,
      WebRTC_WOWZA,
      MapButtonComponent
    },
    name: "StationControlMode",
    props: {
        updatedDate : {
        type: String,
        default: ""
        },
    },
    data() {
        return {
          //index: 0,
          moment: moment,
          mapUtils: new MapUtils(),
          loadMap : 'kakao',
          viewSrc: "",

          isdrModelShow:true,
          eventInfo : null,
          contentsId: null,
          thSelectOption:'arctic',
          imageSrc: require("../../assets/images/thermal_img/thermal_arctic.png"),


          //key event
          useKeyCodes : [
              'keyR',
              'keyF',
              'keyQ',
              'keyE',
              'keyW',
              'keyA', 'keyS', 'keyD', 'keyZ', 'keyX', 'keyC', 'keyV'],
          activeKeys: [],
          droneRotationAngle: 0,
          droneAngle : 0,
          rotationCameraAngle: 0, // 현재 회전 각도
          rotationXAxisAngle: 0,
          rotationYAxisAngle: 0,
          rotationDronePitchAngle: 0,
          rotationStep: 2.5, // 각 단계당 회전 각도
          isActive: [false, false, false, false],
          isFlightStarted: false,
          isDoorOpened: false, // 초기 도어 상태 (닫힘)
          isDroneOnline : false, // 초기 드론 상턔 (꺼짐)
          isModalVisible: false, // 모달 창 표시 여부
          modalMessage: '', // 모달에 표시될 메시지
          isCoordinateFlag : false, // 지도 좌표 선택 위한 플레그
          isCoordinateOpen: false, // 좌표 비행 입력 모달
          isDroneTakeoffOpen : false, // 드론 이륙 모달
          isFlashing: false, // Controls the flashing effect
          isRTHModalOpen: false,
          isRTHStopModalOpen: false,
          wideData: {top: '20px'},
          location: [],
          lat : null,
          lng : null,
          buttons: ['WIDE', 'ZOOM', 'TH', 'PIP'], // 버튼 이름 리스트

          focusOptionList : [{ value : "MF", name:"수동", modeValue : 0},
            { value : "AFC", name: "자동", modeValue : 2},
            // { value : "AFS", name:"화면 클릭", modeValue : 1}
          ],
          initWideFocusValue : null,
          wideFocusValue: 0,
          wideFocusOptionSelect : "MF",
          wideFocusMinValue : 0,
          wideFocusMaxValue : 100,

          initZoomFocusValue : null,
          zoomFocusValue: 0,
          zoomFocusOptionSelect : "MF",
          zoomFocusMinValue : 0,
          zoomFocusMaxValue : 100,

          showButtons : [],
          cameraSelectButton: 'WIDE', // 선택된 버튼의 이름
          locationSize : null,

          zoomValue: 2,
          initZoomValue : null,
          zoomMaxValue : 50,

          isStationChecked: false,
          isDroneFightControlChecked: false,
          isCameraControlChecked: false,
          onIndexes: [1,2,3,4,5,6],
          ko: ko,
          startYmd: null,
          endYmd: null,
          currentPage: 0,
          size: 10,
          keywordType: "all",
          keyword: "",
          total: 0,
          flashingTimer : null,
          isStopFlashingCall : false,

          checkedStations: [],
          eventId : null,

          //station
          initDockStatus : false,
          stationInfo : null,
          EDockModeCode : EDockModeCode,
          EModeCode: EModeCode,
          RainfallEnum : RainfallEnum,

          ControlKeyCode : ControlKeyCode,
          GimbalKeyCode : GimbalKeyCode,

          dockChannelInfo : null,
          droneChannelInfo: null,
          dockChannelManagerInfo : null,
          droneChannelManagerInfo : null,
          storeDockInfo : null,
          storeDroneInfo : null,

          //drc control data
          useManualControlState : {
            stationFlightController : false,
          },

          currentCamera : null,
          cameraIndex : null,
          cameraPayloadSerial : null,
          cameraList : [],

          //mqtt
          deviceTopicInfo : {
            sn: null,
            pubTopic: '',
            subTopic: ''
          },

          useManualControl : null,

          //mqtt 이용 데이터
          useMqtt : null,
          useConnectMqtt : null,
          useDroneControlMqttEvent : null,

          useConnectWebSocket : null,
          resultUseDroneControlWsEvent : null,

          sendX : 0.5,
          sendY : 0.5,
          gimbalSendValue : 10.0,
          addSpeedStepList : [0.1, 1, 4],
          pitchSpeed : 0.0,
          yawSpeed : 0.0,
          stationMissionStatusInfo : null,

          droneSpeedRange : {
            max : 15,
            min : 2
          },
          droneAltitudeRange : {
            max : 1000,
            min : 20
          },

          //location
          isLocationReq : false,
          updateDroneLocationTimer : null,

          //camera toggle 20241127
          isCameraShot: true,
          showMessage: false,
          playMessage: false,
          isRecording: false,
          playTimer: 0,

          //드론 takeoff 설정
          droneTakeoffHeight: 100,
          droneTakeoffDockSecurityHeight: 30,
          droneTakeOffRTLHeight : 100,
          droneTakeoffRCLostAction : "RETURN_HOME",
          droneTakeOffSpeed : 5,
          droneTakeoffRtlMode : "PRESET_HEIGHT",
          droneTakeoffCommanderModeLostAction : "EXECUTE_RC_LOST_ACTION",
          droneTakeoffCommanderFlightMode : "SETTING_HEIGHT",
          droneTakeoffCommanderFlightHeight : null,

        };
    },
    computed: {
        stationName: function() {
          return this.stationInfo == null || this.stationInfo.managerData == null || this.stationInfo.managerData.tag == null ? '-' : this.stationInfo.managerData.tag + '번 ' + this.stationInfo.deviceName
        },
        ...mapState({
          currentLang: state => state.store.currentLang,
          userType: state => state.store.userType,
          accountId: state => state.store.accountId,

          dockInfo: state => state.station.deviceDockInfo,
          droneInfo: state => state.station.deviceDroneInfo,
          deviceDockDebugStatus: state => state.station.deviceDockDebugStatus,

          mqttState: state => state.station.mqttState,
          clientId: state => state.station.clientId,

        }),
        axisClass() {
            return {
                'z-axis': this.axisDirection === 'Z' || this.axisDirection === 'V',
                'x-axis': this.axisDirection === 'X' || this.axisDirection === 'C'
            };
        },

        // 비디오 녹화 시간 mm:ss 20241127
        formattedTime() {
          const minutes = String(Math.floor(this.playTimer / 60)).padStart(2, "0");
          const seconds = String(this.playTimer % 60).padStart(2, "0");
          return `${minutes}:${seconds}`;
        },
    },
    watch : {
        deviceTopicInfo : {
          handler(value){
            if(this.useMqtt !== null) {
              this.useMqtt.setStoreMqttState(value)
            }
          },
          deep: true
        },
        currentLang(value){
          this.initMapData(value);
        },
        mqttState(value){
          console.log("mqttState",value)
        },
        isStationChecked(value) {
          if(value && this.stationInfo.modeCode !== undefined && this.stationInfo.modeCode !== EDockModeCode.Remote_Debugging.code) {
            // this.stationDebugAction("DebugModeOpen")
          }

          if(value && this.stationInfo.modeCode !== undefined && this.stationInfo.modeCode === EDockModeCode.Remote_Debugging.code) {
            // this.stationDebugAction("DebugModeClose")
          }
        },
        useManualControlState : {
          handler(info){
            console.log("useManualControlState useManualControlState",info)
          },
          deep: true
        },
        deviceDockDebugStatus : {
          handler(value){
            value;
            // console.log("deviceDockDebugStatus")
            // console.log(value)
            // console.log("deviceDockDebugStatus")
          },
          deep: true
        },
        dockInfo : {
          handler(info){
            if(this.stationInfo == null) {
              return
            }
            let findDockInfo = info[this.stationInfo.deviceSerial];

            if(findDockInfo === undefined) {
              this.dockChannelManagerInfo = null;
              this.dockChannelInfo = null;
            }

            if(findDockInfo.base !== undefined && findDockInfo.base.modeCode !== this.stationInfo.modeCode) {
              if(findDockInfo.base.modeCode === EDockModeCode.Working.code) {
                if(this.stationMissionJobLog == null) {
                  this.getStationMissionJob()
                }
              }  else {
                this.initStationMissionJob()
              }
              this.stationInfo.modeCode = findDockInfo.base.modeCode
            }

            if(this.dockChannelManagerInfo == null && this.stationInfo.modeCode !== undefined && this.stationInfo.modeCode !== EDockModeCode.Disconnected.code) {
              this.getStationChannelManagerInfo(this.stationDeviceId,"dock");
            }

            if(this.dockChannelManagerInfo != null && this.dockChannelInfo == null && this.stationInfo.modeCode !== undefined && this.stationInfo.modeCode !== EDockModeCode.Disconnected.code) {
              this.getChannelInfo(this.dockChannelManagerInfo.channelId, "dock")
            }

            this.storeDockInfo = findDockInfo;

            if(findDockInfo.base != undefined && this.storeDockInfo.base.latitude != 0 && this.storeDockInfo.base.longitude != 0) {
              this.stationInfo.managerData.lat = this.storeDockInfo.base.latitude;
              this.stationInfo.managerData.lng = this.storeDockInfo.base.longitude;
              this.deviceInfoLatLng("Dock", this.stationInfo)
            }

            if(findDockInfo.base !== undefined) {
              this.isDoorOpened = findDockInfo.base.coverState === 1 ? true : false
            }

            if(!this.initDockStatus && findDockInfo.base !== undefined) {
              this.initDockStatus = true;
              this.setDockStatus(findDockInfo.base)
            }
          },
          deep: true
        },
        droneInfo : {
          handler(info){
            if(this.stationInfo == null) {
              return
            }
            let findDroneInfo = info[this.stationInfo.childSerial];
            if(findDroneInfo == undefined) {
              if(this.storeDroneInfo != null) {
                this.$refs.map.onDeleteMarkerAt(MapDataType.ID_DRONE, this.storeDroneInfo.sn);
              }
              this.storeDroneInfo = null;
              if(this.droneChannelManagerInfo != null) {
                this.$refs.map.onDeleteLineAt(MapDataType.ID_DRONE, this.droneChannelManagerInfo.deviceLogId)
              }
              //드론 카메라 정보 초기화
              this.cameraPayloadSerial = null;
              this.cameraSelectButton = null;
              this.cameraIndex = null;
              this.showButtons = [];
              //드론 카메라 정보 초기화
              this.isDroneOnline = false;

              this.droneChannelManagerInfo = null;
              this.droneChannelInfo = null;
              this.isStopFlashingCall = false
              this.isDroneFightControlChecked = false
              this.$refs.manualRemote.checked = false

              return
            }

            this.storeDroneInfo = findDroneInfo;
            this.isDroneOnline = true;
            if(this.storeDroneInfo != null && (this.storeDroneInfo.modeCode === EModeCode.Landing.code || this.storeDroneInfo.modeCode === EModeCode.Disconnected.code) && this.droneChannelManagerInfo != null) {
              this.$refs.map.onDeleteLineAt(MapDataType.ID_DRONE, this.droneChannelManagerInfo.deviceLogId)
            }

            if(this.storeDroneInfo != null && this.storeDroneInfo.modeCode === EModeCode.Return_To_Home.code) {
              this.startFlashing();
            }
            if(this.storeDroneInfo != null && (this.storeDroneInfo.modeCode === EModeCode.Landing.code
                || this.storeDroneInfo.modeCode === EModeCode.Disconnected.code
                || this.storeDroneInfo.modeCode === EModeCode.Forced_Landing.code
                || this.storeDroneInfo.modeCode === EModeCode.Three_Blades_Landing.code)) {
              this.stopFlashing(false);
            }

            if(this.stationInfo.childStationDevice != null) {
              if(this.storeDroneInfo.modeCode !== this.stationInfo.childStationDevice.modeCode) {
                this.stationInfo.childStationDevice.modeCode = this.storeDroneInfo.modeCode
              }

              if(this.droneChannelManagerInfo == null && this.stationInfo.childStationDevice.modeCode !== undefined && this.stationInfo.childStationDevice.modeCode !== EModeCode.Disconnected.code) {
                this.getStationChannelManagerInfo(this.stationInfo.childStationDevice.stationDeviceId,"drone");
              }

              if(this.droneChannelManagerInfo != null && this.droneChannelInfo == null && this.stationInfo.childStationDevice.modeCode !== undefined && this.stationInfo.childStationDevice.modeCode !== EModeCode.Disconnected.code) {
                if(this.droneChannelManagerInfo.channelId == null) {
                  this.getStationChannelManagerInfo(this.stationInfo.childStationDevice.stationDeviceId,"drone");
                } else {
                  this.getChannelInfo(this.droneChannelManagerInfo.channelId, "drone")
                }
              }

              if(this.cameraPayloadSerial == null && this.stationInfo.childStationDevice.modeCode !== EModeCode.Disconnected.code) {
                this.getStationLiveStreamCapacityList();
              }

              if(this.storeDroneInfo.cameras != null && this.storeDroneInfo.cameras.length > 0 ) {
                if(this.initZoomValue == null && this.storeDroneInfo.cameras[0].zoomFactor != null) {
                  this.zoomValue = Math.ceil(this.storeDroneInfo.cameras[0].zoomFactor);
                  this.initZoomValue = this.zoomValue
                  this.isCameraShot = this.storeDroneInfo.cameras[0].cameraMode !== 1
                }

                if(this.initZoomFocusValue == null) {
                  this.zoomFocusValue  = this.storeDroneInfo.cameras[0].zoomFocusValue
                  this.initZoomFocusValue = this.zoomFocusValue

                  let zoomFocusMode = this.storeDroneInfo.cameras[0].zoomFocusMode
                  this.zoomFocusOptionSelect = zoomFocusMode == 0 ? "MF" : zoomFocusMode == 1 ? "AFS" : "AFC"
                  this.zoomFocusMaxValue =  this.storeDroneInfo.cameras[0].zoomMaxFocusValue
                  this.zoomFocusMinValue =  this.storeDroneInfo.cameras[0].zoomMinFocusValue
                }
              }

              if(this.storeDockInfo != null && this.storeDockInfo.modeCode === EDockModeCode.Working.code && this.stationMissionJobLog === null) {
                this.getStationMissionJobStatusInfo(this.stationInfo.stationDeviceId)
              }

              if(this.storeDroneInfo.payload != null && this.storeDroneInfo.payload.length > 0) {

                this.stationInfo.childStationDevice.managerData.gimbalDirection = this.storeDroneInfo.attitudeHead - this.storeDroneInfo.payload[0].gimbalYaw.toFixed(2);
                this.stationInfo.childStationDevice.managerData.gimbalPitch = this.storeDroneInfo.payload[0].gimbalPitch;
                this.stationInfo.childStationDevice.managerData.gimbalRoll = this.storeDroneInfo.payload[0].gimbalRoll;
                this.applyRotation(this.stationInfo.childStationDevice.managerData.gimbalDirection, this.stationInfo.childStationDevice.managerData.gimbalPitch)
              }
              this.stationInfo.childStationDevice.managerData.rotate = this.storeDroneInfo.attitudeHead;
              this.stationInfo.childStationDevice.managerData.lat = findDroneInfo.latitude
              this.stationInfo.childStationDevice.managerData.lng = findDroneInfo.longitude
              this.deviceInfoLatLng("Drone", this.stationInfo.childStationDevice)

              //드론 북위 기준 헤드 방향 표출(화면 표시 값 )
              this.droneAngle = (this.storeDroneInfo.attitudeHead + 360)%360;
              this.droneRotationAngle = this.droneAngle * -1
              this.droneAngle = this.droneAngle.toFixed(1)
              if(this.$refs.altitude_box_arrow != undefined) {
                this.$refs.altitude_box_arrow.style.top = (50 - this.storeDroneInfo.verticalSpeed.toFixed(1)) + '%';
              }
            }
          },
          deep: true
        },
        deviceMissionStatus: {
          handler(info) {
            if (this.stationInfo == null) {
              return
            }
            let findMissionStatus = info[this.stationInfo.deviceSerial];
            if (findMissionStatus === undefined) {
              this.dockChannelManagerInfo = null;
              this.dockChannelInfo = null;
              return
            }

            this.stationMissionStatusInfo = findMissionStatus;
          },
          deep: true
        },
    },
  beforeRouteEnter(to, from, next) {
    if(stationLicenceCheck(localStorage.getItem("accountId"))) {
      next()
    } else {
      next("/demo/404")
    }
  },
    mounted() {
        this.cameraInit();

        this.domAddEventHandle(window,'keydown',this.handleKeyPress);
        this.domAddEventHandle(window, 'keyup', this.handleKeyup);

        this.stationDeviceId = this.$route.params.stationDeviceId;
        this.getStationInfo();
        this.getStationMissionJob();

        this.setStationMissionPauseResume(true,async (isSuccess) => {
          isSuccess;
          this.isStationChecked = true;
        })
        this.useDroneControlMqttEvent = new UseDroneControlMqttEvent()
        this.useDroneControlMqttEvent.onMounted()

        if(this.useConnectMqtt != null) {
          this.useConnectMqtt.setDeviceDockInfo(true)
        }

        this.useMqtt = new UseMqtt()
        this.useMqtt.setDeviceTopicInfo(this.deviceTopicInfo)

        this.useManualControl = new UseManualControl(this.useManualControlState, this.deviceTopicInfo)

        this.initButtonMap()
        this.$refs.map.isShowLine = true;
      if(this.updateDroneLocationTimer != null) {
        clearInterval(this.updateDroneLocationTimer);
        this.updateDroneLocationTimer = null
      }

        if(this.updateDroneLocationTimer == null) {
          this.updateDroneLocationTimer = setInterval(() => {
            if(this.droneChannelManagerInfo != null) {
              this.getLocation(this.droneChannelManagerInfo.deviceLogId)
            }
          }, 1000);
        }

    },
    beforeDestroy() {

        this.domRemoveEventHandle(window, 'keydown', this.handleKeyPress);
        this.domRemoveEventHandle(window, 'keyup', this.handleKeyup);
        this.stationMqttUnmounted()

        if(this.useConnectWebSocket != null) {
          this.useConnectWebSocket.onWebSocketUnmounted();
          this.useConnectWebSocket = null;
        }

        if(this.resultUseDroneControlWsEvent != null) {
          this.resultUseDroneControlWsEvent.onUnmounted()
          this.resultUseDroneControlWsEvent = null
        }
        if(this.updateDroneLocationTimer != null) {
          clearInterval(this.updateDroneLocationTimer);
          this.updateDroneLocationTimer = null
        }

      if(this.flashingTimer != null) {
        clearInterval(this.flashingTimer); // 깜박거림을 멈춤
        this.flashingTimer = null;
      }
    },
    created() {
        this.$store.dispatch("store/SET_NAVBAR", { nav: 9, sub: 2 });
        this.useConnectWebSocket = new UseConnectWebSocket();
        this.useConnectMqtt = new UseConnectMqtt()
    },
    methods: {
        validateSpeed() {
          const min = this.droneSpeedRange.min;
          const max = this.droneSpeedRange.max;
          const value = parseFloat(this.droneTakeOffSpeed);
          if (isNaN(value)) {
            this.speedWarning = this.$t('station-mode-modal-takeoff-input-speed');
            this.autoFlightSpeed = '';
          } else if (value < min) {
            this.speedWarning = this.$t('station-mode-modal-takeoff-input-max-speed', { min });
            this.autoFlightSpeed = min;
          } else if (value > max) {
            this.speedWarning = this.$t('station-mode-modal-takeoff-input-min-speed', { max });
            this.autoFlightSpeed = max;
          } else {
            this.speedWarning = '';
          }
        },
        moveMapCenter(type) {
          if(type === "Dock" && this.storeDockInfo != null && this.storeDockInfo.base != undefined) {
            this.$refs.map.onLngLatToMoveCenter(this.storeDockInfo.base.longitude, this.storeDockInfo.base.latitude);
          }
          if(type === "Drone" && this.storeDroneInfo != null) {
            this.$refs.map.onLngLatToMoveCenter(this.storeDroneInfo.longitude, this.storeDroneInfo.latitude);
          }
        },
        playerReload(stationDeviceId , type) {
          if(stationDeviceId !== null && type !== undefined) {
            if(type==="Dock") {
              this.dockChannelInfo = null;
              this.dockChannelManagerInfo = null;
            }
            if(type==="drone") {
              this.$refs.dronePlayer.playStop();
              this.droneChannelInfo = null;
              this.droneChannelManagerInfo = null;
            }
            this.getStationChannelManagerInfo(stationDeviceId,type);
          }
        },
        initButtonMap() {
          this.$refs.buttonMap.showMapUserCenter()
        },
        hideButtonMap(){
          this.$refs.buttonMap.hideMapUserCenter()
        },
        controlModeOFf(){
            const userConfirmed = confirm(this.$t('station-mode-btn-close'))
            if(userConfirmed && true) {
              this.setStationMissionPauseResume(false,async (isSuccess) => {
                if(isSuccess === true) {
                  this.isStationChecked = false;
                }
              })
            }
            if(userConfirmed){
              this.$router.push({ name: "stationControl"})
            } else {
                console.log('사용자가 취소를 선택했습니다.')
            }
        },
        isOn(index){
            return this.onIndexes.includes(index);
        },
        cameraButton(button) {
          if(this.cameraSelectButton === button) {
            return
          }

          let oldButton = this.cameraSelectButton;

          let select = this.changeShowNameToDroneCameraType(button)
          let videoId = this.stationInfo.deviceSerial + '/' + this.cameraIndex + '/' + (select + '-0')
          let param = {
            "videoId" : videoId,
            "videoType" : select,
          }

          if(button === "PIP" && (oldButton === "WIDE" || oldButton === "ZOOM")) {
            param.videoType = this.changeShowNameToDroneCameraType("TH")
          }

          fetchStationLiveStreamSwitch(param).then(res => {
            if (res.status === 200 && res.data.result === 0) {
              this.cameraSelectButton = button; // 선택된 버튼 업데이트
              if(button !== "WIDE" && button !== "ZOOM") {
                let data = {
                  payloadIndex : this.cameraIndex,
                  enable : button === "PIP",
                }
                this.sendStationDevicePayload(PayloadCommandsEnum.CameraScreenSplit, data)
              }
            } else {
              this.cameraSelectButton = oldButton
              console.error("fetchLiveStreamSwitch", res)
            }
          })
        },
        increaseFocus(type) {
          console.log("increaseFocus")
          if(type === "ZOOM") {
            this.zoomFocusValue = this.zoomFocusValue + 1;
            if (this.zoomFocusValue >= this.zoomFocusMaxValue){
              this.zoomFocusValue = this.zoomFocusMaxValue
            }

            this.sendZoomFocusValue(this.zoomFocusValue)
          }
        },
        decreaseFocus() {
          console.log("decreaseFocus")
          this.zoomFocusValue = this.zoomFocusValue - 1;
            if (this.zoomFocusValue <= this.zoomFocusMinValue){
              this.zoomFocusValue = this.zoomFocusMinValue
            }
            this.sendZoomFocusValue(this.zoomFocusValue)
        },
        sendZoomFocusValue(focusValue) {
          let data = {
            payloadIndex : this.cameraIndex,
            cameraType : "zoom",
            focusValue : focusValue,
          }
          let callFocusChange = function(isSuccess) {
            if(isSuccess) {
              console.log("increaseFocus 1", isSuccess)
              // this.zoomFocusOptionSelect = event.target.value
            }
            console.log("increaseFocus", isSuccess)
          }
          this.sendStationDevicePayload(PayloadCommandsEnum.CameraFocusModeSet, data, callFocusChange)

        },
        setZoomFocusOptionChange(event) {
          let data = {
            payloadIndex : this.cameraIndex,
            cameraType : "zoom",
            focusMode : event.target.value === "MF" ? 0 : event.target.value === "AFS" ? 1 : 2,
          }
          let callFocusChange = function(isSuccess) {
            if(isSuccess) {
              this.zoomFocusOptionSelect = event.target.value
            }
          }
          this.sendStationDevicePayload(PayloadCommandsEnum.CameraFocusModeSet, data, callFocusChange)
        },
        zoomUpdateFocus(event) {
          this.zoomFocusValue = parseFloat(event.target.value);
        },
        wideUpdateFocus(event) {
            this.wideFocusValue = parseFloat(event.target.value);
        },
        increaseZoom() {
            if (this.zoomValue < this.zoomMaxValue){
                let increase = this.zoomValue + 10
                if(increase > this.zoomMaxValue) {
                  increase = this.zoomMaxValue
                }
                this.zoomValue = parseFloat((increase).toFixed(1))
                this.updateZoom()
            }
        },
        decreaseZoom() {
            if (this.zoomValue > 2) {
                let decrease = this.zoomValue - 10
                if(decrease < 2) {
                  decrease = 2
                }
                this.zoomValue = parseFloat((decrease).toFixed(1));
                this.updateZoom()
            }
        },
        inputZoom(event) {
            this.zoomValue = parseFloat(event.target.value);
        },
        updateZoom() {
          if(this.cameraIndex == null) {
            console.error("drone not connected")
            return
          }
          let data = {
            payloadIndex : this.cameraIndex,
            cameraType : "zoom",
            zoomFactor : this.zoomValue
          }
          this.sendStationDevicePayload(PayloadCommandsEnum.CameraFocalLengthSet, data)
        },
        updateImage() {
            switch (this.thSelectOption) {
                case 'arctic':
                this.imageSrc = require("../../assets/images/thermal_img/thermal_arctic.png");
                break;
                case 'black_hot':
                this.imageSrc = require("../../assets/images/thermal_img/thermal_black_hot.png");
                break;
                case 'cold_spot':
                this.imageSrc = require("../../assets/images/thermal_img/thermal_cold_spot.png");
                break;
                case 'fulgurite':
                this.imageSrc = require("../../assets/images/thermal_img/thermal_fulgurite.png");
                break;
                case 'gray':
                this.imageSrc = require("../../assets/images/thermal_img/thermal_gray.png");
                break;
                case 'hot_iron':
                this.imageSrc = require("../../assets/images/thermal_img/thermal_hot_iron.png");
                break;
                case 'hot_metal':
                this.imageSrc = require("../../assets/images/thermal_img/thermal_hot_metal.png");
                break;
                case 'hot_spot':
                this.imageSrc = require("../../assets/images/thermal_img/thermal_hot_spot.png");
                break;
                case 'iron_red':
                this.imageSrc = require("../../assets/images/thermal_img/thermal_iron_red.png");
                break;
                case 'medical':
                this.imageSrc = require("../../assets/images/thermal_img/thermal_medical.png");
                break;
                case 'rainbow':
                this.imageSrc = require("../../assets/images/thermal_img/thermal_rainbow.png");
                break;
                case 'rainbow_1':
                this.imageSrc = require("../../assets/images/thermal_img/thermal_rainbow_1.png");
                break;
                case 'rainbow_2':
                this.imageSrc = require("../../assets/images/thermal_img/thermal_rainbow_2.png");
                break;
                case 'tint':
                this.imageSrc = require("../../assets/images/thermal_img/thermal_tint.png");
                break;
                case 'white_hot':
                this.imageSrc = require("../../assets/images/thermal_img/thermal_white_hot.png");
                break;
                default:
                this.imageSrc = require("../../assets/images/thermal_img/thermal_arctic.png");
            }
        },
        domAddEventHandle(target,type,eventListner){
            if(target.addEventListener){
                target.addEventListener(type,eventListner,true)
            }else{
                target.attachEvent('on' + type, eventListner, true);
            }
        },
        domRemoveEventHandle(target,type,eventListner){
            if(target.removeEventListener){
                target.removeEventListener(type,eventListner,true)
            }else{
                target.detachEvent('on' + type, eventListner, true);
            }
        },
        handleKeyPress(event){
            const key = event.code;
            if(!Object.values(this.ControlKeyCode).includes(key) && !Object.values(this.GimbalKeyCode).includes(key)) {
              return;
            }

            //드론 조종 관련 키 이벤트 처리(입력)
            if(Object.values(this.ControlKeyCode).includes(key) && this.useManualControlState.stationFlightController) {
              let isSendUseManualControl = true

              if((key === "KeyW" && this.activeKeys.includes("KeyS")) || (key === "KeyS" && this.activeKeys.includes("KeyW"))) {
                isSendUseManualControl = false
              }
              if((key === "KeyA" && this.activeKeys.includes("KeyD")) || key === "KeyD" && this.activeKeys.includes("KeyA")) {
                isSendUseManualControl = false
              }
              if((key === "KeyQ" && this.activeKeys.includes("KeyE")) || key === "KeyE" && this.activeKeys.includes("KeyQ")) {
                isSendUseManualControl = false
              }
              if((key === "KeyR" && this.activeKeys.includes("KeyF")) || key === "KeyF" && this.activeKeys.includes("KeyR")) {
                isSendUseManualControl = false
              }

              if (!this.activeKeys.includes(key) && isSendUseManualControl) {
                this.activeKeys.push(key);
              }

              if(isSendUseManualControl) {
                this.useManualControl.onKeydown(event)
              }
            }

            if(Object.values(this.GimbalKeyCode).includes(key) && this.isCameraControlChecked) {
              let isSendGimbalKey = true
              if ((key === "KeyZ" && this.activeKeys.includes("KeyV")) || (key === "KeyV" && this.activeKeys.includes("KeyZ"))) {
                isSendGimbalKey = false
              }
              if ((key === "KeyX" && this.activeKeys.includes("KeyC")) || key === "KeyC" && this.activeKeys.includes("KeyX")) {
                isSendGimbalKey = false
              }

              if (!this.activeKeys.includes(key)) {
                if (isSendGimbalKey) {
                  this.activeKeys.push(key);
                  if(key === "KeyZ" || key === "KeyV") {
                    this.yawSpeed = 0.0;
                  }

                  if(key === "KeyX" || key === "KeyC") {
                    this.pitchSpeed = 0.0;
                  }
                }
              }

              if (isSendGimbalKey) {
                let yawSendValue = 0;
                let pitchSendValue = 0;
                let abs = 1;
                if(key === "KeyZ" || key === "KeyC") {
                  abs = -1
                }
                if(key === "KeyV" || key === "KeyZ") {
                  yawSendValue = abs * this.gimbalSendValue;
                }
                if(key === "KeyX" || key === "KeyC") {
                  pitchSendValue = abs * this.gimbalSendValue;
                }

                console.log("handleKeyPress", yawSendValue, pitchSendValue)
                let data = {
                  locked: false,
                  payloadIndex: this.cameraIndex,
                  yawSpeed: yawSendValue,
                  pitchSpeed: pitchSendValue
                }

                if(this.cameraIndex != null) {
                  this.sendStationDevicePayload(PayloadCommandsEnum.CameraScreenDrag, data)
                } else {
                  console.error("drone not connected")
                }
              }
            }
            //
            // if (['keyR', 'keyF', 'keyQ', 'keyE', 'keyW', 'keyA', 'keyS', 'keyD', 'keyZ', 'keyX', 'keyC', 'keyV'].includes(key)) {
            //
            // }
            //
            // this.triggerAction(key);
            // this.applyRotation(key);
        },
        handleKeyup(event) {
            const key = event.code;//event.code.toUpperCase();
            if(!Object.values(this.ControlKeyCode).includes(key) && !Object.values(this.GimbalKeyCode).includes(key)) {
              return;
            }

            this.applyRotation()
            //드론 조종 관련 키 이벤트 처리(입력)
            if(this.useManualControlState.stationFlightController) {
              this.useManualControl.onKeyup()
            }

            let index = this.activeKeys.findIndex((item) => item === key )
            if(index > -1) {
              this.activeKeys.splice(index, 1);
            }

            if(key == "KeyZ" || key == "KeyV" ) {
              this.yawSpeed = 0.0;
            }
            if(key == "KeyX" || key == "KeyC" ) {
              this.pitchSpeed = 0.0;
            }
        },
        applyRotation(yaw, pitch) {

            // 축 회전 상태를 저장할 변수
            let rotationAxis = 'X';
            this.rotationCameraAngle = yaw

            // // Z와 V 버튼의 회전 각도가 45도 이상일 때 Y축 회전으로 변경
            if (Math.abs(this.rotationCameraAngle % 360) >= 45) {
                rotationAxis = 'Y';
            }
            if (Math.abs(this.rotationCameraAngle % 360) >= 180) {
                rotationAxis = 'X';
            }
            if (Math.abs(this.rotationCameraAngle % 360) >= 270) {
                rotationAxis = 'Y';
            }

              this.rotationDronePitchAngle = pitch
          if (rotationAxis === 'Y') {
            this.rotationYAxisAngle = pitch//(this.rotationYAxisAngle - this.rotationStep) % 360; // X키가 Y축으로 회전
          } else {
            this.rotationXAxisAngle = pitch //(this.rotationXAxisAngle - this.rotationStep) % 360; // X키가 X축으로 회전
          }
            // console.log(`Rotation Camera Angle: ${this.rotationCameraAngle}, Rotation X-Axis Angle: ${this.rotationXAxisAngle}, Rotation Y-Axis Angle: ${this.rotationYAxisAngle}`);
        },
        toggleDroneActive(index) {
            //this.$set(this.isActive, index, !this.isActive[index]);  // 해당 버튼의 활성화 상태를 토글
            if(index === 3){
                window.confirm(this.$t('station-mode-alert-permissions'));
            }else {
                this.$set(this.isActive, index, !this.isActive[index]);
            }
        },

        //station btn
        confirmFlightStart() {
            if(this.storeDockInfo === null && this.storeDockInfo.base === undefined) {
              alert(this.$t('station-mode-alert-connection'))
              return;
            }

            if(this.storeDockInfo.base.modeCode !== EDockModeCode.Idle.code) {
              alert(this.$t('station-mode-alert-flight-description'))
              return
            }

            const userConfirmed = window.confirm(this.$t('station-mode-alert-flight-question'));
            if (userConfirmed) {
                // this.isFlightStarted = true;  // 확인을 누르면 비행 시작 상태로 전환
              const id = crypto.randomUUID();
              let send = {
                "stationDeviceId": this.stationDeviceId,
                "cls": "DJI",
                "flightId": "FLYTO_"+ id,

                "targetLongitude" : this.storeDockInfo.base.longitude,
                "targetLatitude" : this.storeDockInfo.base.latitude,
                "targetHeight" : this.droneTakeoffHeight + this.storeDockInfo.base.height,

                "securityTakeoffHeight" : this.droneTakeoffDockSecurityHeight,
                "rthAltitude" : this.droneTakeOffRTLHeight,
                "rcLostAction" : this.droneTakeoffRCLostAction,

                "maxSpeed" : this.droneTakeOffSpeed,
                "rthMode" : this.droneTakeoffRtlMode,

                "commanderModeLostAction" : this.droneTakeoffCommanderModeLostAction,
                "commanderFlightMode" : this.droneTakeoffCommanderFlightMode,
                "commanderFlightHeight" : this.droneTakeoffDockSecurityHeight,
              }

              fetchStationDeviceDockJobTakeoffToPoint(send).then((res) => {
                console.log("fetchStationDeviceDockJobTakeoffToPoint", res)
                if( res.status === 200 && res.data.result === 0) {
                  this.isDroneTakeoffOpen = false;
                }
              });
            }
        },
        confirmFlightStop() {
            const userConfirmed = window.confirm(this.$t('station-mode-alert-flight-stop-question'));
            if (userConfirmed) {
                this.isFlightStarted = false;  // 확인을 누르면 비행 중지 상태로 전환
            }
        },
        handleDroneOpen(isOpen) {
            let confirmMessage = isOpen ? this.$t('station-mode-alert-drone-on') : this.$t('station-mode-alert-drone-off')
            let action = isOpen ? "DroneOpen" : "DroneClose"
            const userConfirmed = window.confirm(confirmMessage);
            if (userConfirmed) {
              this.stationDebugAction(action)
              // this.modalMessage = "도어를 여는 중...";
              // this.isModalVisible = true; // 모달 창 표시
              // setTimeout(() => {
              // this.isModalVisible = false; // 모달 창 닫기
              // this.isDoorOpened = true; // 도어 열린 상태로 변경
              // }, 3000); // 3초 후 모달 닫기
            }
        },
        handleDoorOpen(isOpen) {
            let confirmMessage = isOpen ? this.$t('station-mode-alert-dock-open') : this.$t('station-mode-alert-dock-close')
            let action = isOpen ? "CoverOpen" : "CoverClose"
            const userConfirmed = window.confirm(confirmMessage);
            if (userConfirmed) {
              this.stationDebugAction(action)
              // this.modalMessage = "도어를 여는 중...";
              // this.isModalVisible = true; // 모달 창 표시
              // setTimeout(() => {
              // this.isModalVisible = false; // 모달 창 닫기
              // this.isDoorOpened = true; // 도어 열린 상태로 변경
              // }, 3000); // 3초 후 모달 닫기
            }
        },
        flagCoordinate(){
          this.isCoordinateFlag = !this.isCoordinateFlag;
          console.log("flagCoordinate",this.isCoordinateFlag)
        },
        openCoordinateModal() {
            this.isCoordinateOpen = true;
        },
        closeCoordinateModal() {
            this.isCoordinateOpen = false;
        },
        openDroneTakeoffModal() {
            this.isDroneTakeoffOpen = true;
        },
        closeDroneTakeoffModal() {
            this.isDroneTakeoffOpen = false;
        },


        //RTH mode
        handleRTHClick() {
            if( this.stationInfo == null) {
              return
            }
            if (this.isFlashing) {
                // 깜박거림이 진행 중일 때 다시 버튼을 누르면 중단 여부를 묻는 알림을 띄움
                const stopFlashing = confirm(this.$t('station-mode-rtk-attempt-interrupted'));
                if (stopFlashing) {

                  let param = {
                    "cls" : "dji",
                    "deviceSerial" : this.stationInfo.deviceSerial,
                    "method" : "ReturnHomeCancel",
                    "action" : null
                  }
                  fetchStationDeviceDockDebug(param).then((res) => {
                    console.log("handleRTHClick ReturnHomeCancel", res)
                    if (res.status == 200 && res.data.result == 0) {
                      this.stopFlashing(true);  // 중단 시 true 값을 전달
                      this.showRTHStopModal();
                    }
                  })
                }
            } else {
                // 깜박거림이 진행 중이 아닐 때 RETURN-TO-HOME 실행 여부를 물어봄
                if (confirm(this.$t('station-mode-rtk-practice'))) {
                    let param = {
                      "cls" : "dji",
                      "deviceSerial" : this.stationInfo.deviceSerial,
                      "method" : "ReturnHome",
                      "action" : null
                    }
                    fetchStationDeviceDockDebug(param).then((res) => {
                      console.log("handleRTHClick ReturnHome", res)
                      if (res.status == 200 && res.data.result == 0) {
                        this.startFlashing();
                      }
                    })
                }
            }
        },
        startFlashing() {
            if(this.flashingTimer != null) {
              return
            }
            this.isStopFlashingCall = false

            this.isFlashing = true;
            // this.isRTHCancelled = false; // 중단 여부를 추적하는 플래그

            // 깜박거림을 시작
            this.flashingTimer = setInterval(() => {
                this.isFlashing = !this.isFlashing;
            }, 3000);

            // 5초 후에 깜박거림을 멈추고, 중단되지 않았다면 완료 모달을 표시
            // this.flashingTimeout = setTimeout(() => {  // 타이머를 변수에 저장하여 나중에 취소 가능
            //     if (!this.isRTHCancelled) {  // 중단되지 않은 경우에만 완료 모달 표시
            //         this.stopFlashing(false);  // false 값을 전달하여 자동 종료 처리
            //         this.showRTHModal();  // 자동 종료 후 완료 모달을 표시
            //     }
            // }, 5000);
        },
        stopFlashing(userStopped){
            if(this.isStopFlashingCall) {
              return
            }
            this.isStopFlashingCall = true

            if(this.flashingTimer != null) {
              clearInterval(this.flashingTimer); // 깜박거림을 멈춤
              this.flashingTimer = null;
            }

            // clearTimeout(this.flashingTimeout); // 이전 타이머 취소
            this.isFlashing = false;
            if (userStopped) {
                this.isRTHCancelled = true;  // 중단되었음을 표시
                this.isRTHStopModalOpen = true;  // 중단 모달을 염
            } else {
                this.isRTHCancelled = false; // 자동 종료 시에는 취소되지 않았음을 표시
            }
        },
        showRTHModal() {
            this.isRTHModalOpen = true; // 모달을 염
        },
        closeRTHModal() {
            this.isRTHModalOpen = false; // 모달을 닫음
        },
        showRTHStopModal() {
            this.isRTHStopModalOpen = true; // 모달을 염
        },
        closeRTHStopModal() {
            this.isRTHStopModalOpen = false; // 모달을 닫음
        },
        switchViews() {
            const bigViewElement = this.$refs.bigView;
            const smViewElement = this.$refs.smView;

            const mapElement = this.$refs.mapRoot;
            const videoElement = this.$refs.video;
            if (bigViewElement.contains(videoElement)) {
                this.initButtonMap();
                smViewElement.appendChild(videoElement);
                bigViewElement.appendChild(mapElement);
                this.$refs.video.style.height = '100%';


            } else {
                this.hideButtonMap()
                // videoElement가 smViewElement에 있는 경우, 위치를 스위칭
                bigViewElement.appendChild(videoElement);
                smViewElement.appendChild(mapElement);
                this.$refs.smView.style.flexDirection = 'column-reverse';

            }
            this.$refs.map.setLayout()
            if(this.stationInfo.childStationDevice != null && this.stationInfo.childStationDevice.managerData.lat != null && this.stationInfo.childStationDevice.managerData.lng !== null) {
              this.$refs.map.onLngLatToMoveCenter(this.stationInfo.childStationDevice.managerData.lng, this.stationInfo.childStationDevice.managerData.lat)
              return
            }
            if(this.stationInfo.managerData.lat != null && this.stationInfo.managerData.lng !== null) {
              this.$refs.map.onLngLatToMoveCenter(this.stationInfo.managerData.lng, this.stationInfo.managerData.lat)
              return
            }

        },

        //region map
        onMapCenter() {
          this.$refs.map.onSetZoomLevel(getDefaultMapZoomLevel(this.currentLang));
          this.$refs.map.onLngLatToMoveCenter("126.8722084", "37.5797583")
        },
        createMissionMapLine(id, locationGroup) {
          let mapDataType = MapDataType.ID_WAYPOINT
          var mapLineData = new MapLineData();
          mapLineData.id = id;
          mapLineData.locationGroup = locationGroup;
          mapLineData.isMain = true;
          mapLineData.isShow = true;
          if (this.$refs.map.onIsLine(mapDataType, id)) {
            this.$refs.map.onModifyLineReplaceAt(mapDataType, mapLineData);
          } else {
            this.$refs.map.onCreateLine(mapDataType, mapLineData);
          }
        },
        //endregion

        //region 미션 관련 함수 -----------------------------------------------------------------
        getStationMissionJobStatusInfo() {
          fetchStationMissionJobStatus(this.stationDeviceId).then((res) => {
              if (res.data.result == 0) {
                this.stationMissionStatusInfo = res.data.data;
              }
          })
        },
        getCameraListId(id){
          return this.cameraTypeList.findIndex(item => {return item.id == id})
        },
        cameraInit(){
          getMissionCameraList().then(res => {
            var data = JSON.parse(res.data.data)
            this.cameraTypeList = data.DJI
            //console.log(res);
          }).then(()=>{
          })
        },
        getMissionInfo(missionId){
          fetchMissionInfo(missionId).then((res) => {
            if (res.data.result == 0) {
              this.missionInfo = res.data.data;
              this.altitude = res.data.data.altitude
              this.title = res.data.data.title
              this.autoFlightSpeed = res.data.data.autoFlightSpeed
              this.finishedActionType = res.data.data.finishedActionType
              this.getMissionLocation(missionId)
            }
          })
        },
        getMissionLocation(missionId) {
          let params = {
            eventId: this.eventId,
            missionId: missionId
          };
          fetchMissionLocationList(params).then((res) => {
            if (res.status == 200 && res.data.result == 0) {
              this.missionLocationList = res.data.data.content

              let missionType = this.missionInfo.missionType

              if(missionType == "Mapping") {
                let mappingData = this.settingMappingData();
                this.missionLocationList.forEach((item) => {
                  this.setCreatePolygonMarker(mappingData, item)
                });
              }
              if(missionType == "WayPoint") {
                let sort = this.missionLocationList.sort((a,b) => {return a.index - b.index})
                this.setMissionWaypointToMap(sort)
              }

            }
          });
        },
        setMissionWaypointToMap(list){
          let mapDataType = MapDataType.ID_WAYPOINT
          if( list.length > 0) {
            this.$refs.map.onLngLatToMoveCenter(list[0].lng,  list[0].lat)

            list.forEach( (item ) => {
              let index = item.index + 1
              let mapMarkerData = new MapMarkerData();
              mapMarkerData.id = index;
              mapMarkerData.lng = item.lng;
              mapMarkerData.lat = item.lat;
              mapMarkerData.rotate = item.azimuth;
              mapMarkerData.name = index;

              if(this.$refs.map.onIsMarker(mapDataType, index ) ) {
                this.$refs.map.onModifyMarkerAt(mapDataType, mapMarkerData);
              } else {
                this.$refs.map.onCreateMarker(mapDataType, mapMarkerData);
              }
            })
            this.createMissionMapLine("way-point" + list.index, list);
          }
        },

        /**폴리곤 생성.*/
        setCreatePolygon(mapDataType){
          var mapPolygonDate = new MapPolygonData();
          if(mapDataType == MapDataType.ID_MAPPING_HIDDEN_POLYGON){
            var centerInfo = this.setCreateCenterInfo()
            mapPolygonDate.setPolygon(MapDataType.ID_MAPPING_HIDDEN_POLYGON)
            mapPolygonDate.setLatLng(centerInfo)
            mapPolygonDate.posList = this.setCreateSqurePolygon(centerInfo ,centerInfo.distance,Number(this.missionInfo.info.angle))
            mapPolygonDate.line = mapPolygonDate.posList
          }
          this.$refs.map.onCreateMappingPolygon(mapPolygonDate,mapDataType);
        },
        setCreatePolygonMarker(mappingData, location){
          let mapDataType = MapDataType.ID_MAPPING_MARKER
          let mapMarkerData = new MapMarkerData();
          let index = location.index+1
          mapMarkerData.id = index;
          mapMarkerData.lng = location.lng;
          mapMarkerData.lat = location.lat;
          mapMarkerData.rotate = 0;
          mapMarkerData.name = index;

          if (this.$refs.map.onIsMarker(mapDataType, index)) {
            this.$refs.map.onModifyMarkerAt(mapDataType, mapMarkerData);
          } else {
            this.$refs.map.onCreateMarker(mapDataType, mapMarkerData);
          }
          if (index > 1) {
            let slice = this.missionLocationList.slice(index - 2, index);
            this.setCreateMapLine(MapDataType.ID_MAPPING_POLYGON_LINE, index - 1, slice);
          }
          this.setCreateLastLine(mappingData, index)
        },
        /**매핑 영역안에 라인 만들때 사용 */
        setCreateMapLine(mapDataType, id, locationGroup) {
          var mapLineData = new MapLineData();
          mapLineData.id = id;
          mapLineData.locationGroup = locationGroup;
          mapLineData.mapDataType = mapDataType;
          mapLineData.isMain = true;
          mapLineData.isShow = true;
          if (this.$refs.map.onIsLine(mapDataType, id)) {
            this.$refs.map.onModifyLineReplaceAt(mapDataType, mapLineData);
          } else {
            this.$refs.map.onCreateLine(mapDataType, mapLineData);
          }
        },
        /**마커가 3개이상부터 찍히면 첫번째 포인트와 마지막 포인트 연결함 */
        setCreateLastLine(mappingData, index){
          if(index > 2){
            let slice = [
              this.missionLocationList[0], // 첫 번째 요소
              this.missionLocationList[index - 1] // 마지막 요소
            ];
            this.setCreateMapLine(MapDataType.ID_MAPPING_POLYGON_LINE, index , slice);
            this.setCreateMappingLine(mappingData)
          }
        },
        setCreateMappingLine(mappingData){
          /**
           *  1. 각도 , 간격, 비행높이 정보를 보낸다.
           *  2. 매핑 뒤에 둘 히든 폴리곤을 생성한다.
           *  3. 히든폴리곤과 다각형에 라인을 생성한다.
           */
          this.sendMappingData( mappingData);

          /**mappingType은 정사영상이냐, 방제용이냐*/
          this.setCreatePolygon(MapDataType.ID_MAPPING_HIDDEN_POLYGON);
          this.$refs.map.onCreateMappingLine("Orthophoto" , this.polygonType)
        },
        /**매핑 영역 밑에 깔리는 폴릭곤 */
        setCreateSqurePolygon(point,distance,angle){
          var polygonPosList = []

          var rightPoint = this.mapUtils.calculateNewPosition(point,90+angle,distance);
          polygonPosList.push(this.mapUtils.calculateNewPosition(rightPoint,0+angle,distance,polygonPosList.length));
          polygonPosList.push(this.mapUtils.calculateNewPosition(rightPoint,180+angle,distance,polygonPosList.length));


          var leftPoint = this.mapUtils.calculateNewPosition(point,270+angle,distance);
          polygonPosList.push(this.mapUtils.calculateNewPosition(leftPoint,180+angle,distance,polygonPosList.length));
          polygonPosList.push(this.mapUtils.calculateNewPosition(leftPoint,0+angle,distance,polygonPosList.length));

          return polygonPosList
        },
        sendMappingData(mappingData){
          this.$refs.map.sendMappingData(mappingData)
        },
        /**다각형 도형의 가운데 정보를 생성한다.*/
        setCreateCenterInfo(){
          var maxDistance = 0
          var center = this.mapUtils.calculateCentroid(this.missionLocationList) //다각형 중점 구하기

          this.missionLocationList.forEach(item => { //다각형 중점에서부터의 각포인트 최대 거리 구하기.
            var distance = this.mapUtils.calculateDistance(item , center)
            if(maxDistance < distance) maxDistance = distance
          })
          var data = {lat : center.lat , lng : center.lng , distance : maxDistance}
          return data
        },
        settingMappingData() {
          let mappingData = null;
          if (this.missionInfo != null) {
            var findIndex = this.getCameraListId(this.missionInfo.info.cameraType);
            var camera = JSON.parse(JSON.stringify(this.cameraTypeList[findIndex]));
            mappingData = new MapMappingData()
            this.missionInfo.info.altitude = this.missionInfo.altitude;
            mappingData.setApiData(this.missionInfo.info)
            mappingData.cameraType = camera;
            this.sendMappingData(mappingData);
          }
          return mappingData
        },
        //endregion

        //region event
        getEvent() {
          if(this.eventId === null) {
            return;
          }
          fetchEvent(this.eventId).then((res) => {
            if (res.data.result == 0) {
              this.eventInfo = res.data.data;
              if(this.$refs.map == undefined) {
                return;
              }
              if(this.eventInfo.lng != null && this.eventInfo.lng != "" && this.eventInfo.lat != null && this.eventInfo.lat != "") {
                if(this.stationInfo.managerData.lng != null &&  this.stationInfo.managerData.lat != null) {
                  this.$refs.map.onLngLatToMoveCenter(this.stationInfo.managerData.lng, this.stationInfo.managerData.lat)
                } else {
                  this.$refs.map.onLngLatToMoveCenter(this.eventInfo.lng, this.eventInfo.lat)
                }
                this.$refs.map.onCreateMapMarker(this.eventId, this.eventInfo.lng, this.eventInfo.lat, MapDataType.ID_SITE);
                this.$refs.map.onSetLngLat(this.eventInfo.lng, this.eventInfo.lat);
              } else {
                this.$refs.map.onAddressToMoveCenter(this.eventInfo.address);
              }
            }
          });
        },
        deviceInfoLatLng(type, info) {
          let mapDataType = null;
          let name = info.deviceName;
          let location = {
            lat : null,
            lng : null,
            azimuth : null,
            gimbalDirection : null,
          }

          location.lat = info.managerData.lat;
          location.lng = info.managerData.lng;
          if(type === "Dock") {
            mapDataType = MapDataType.ID_SCHEDULE_STATION
          }

          if(type === "Drone") {
            mapDataType = MapDataType.ID_DRONE;
            location.azimuth = info.managerData.rotate;
            location.gimbalDirection = info.managerData.gimbalDirection;
          }
          if(mapDataType != null && name != null) {
            this.createMapMarker(mapDataType, info.sn, name, location)
            return;
          }
        },
        createMapMarker(mapDataType, id, name, location) {
          let mapMarkerData = new MapMarkerData();
          mapMarkerData.id = id;
          mapMarkerData.lng = location.lng;
          mapMarkerData.lat = location.lat;
          mapMarkerData.name = name;
          mapMarkerData.userName = name;
          mapMarkerData.userDepartmentName = "";

          if(mapDataType === MapDataType.ID_DRONE) {
            mapMarkerData.rotate = location.azimuth;
            mapMarkerData.gimbalDirection = location.gimbalDirection;
          }

          if (this.$refs.map.onIsMarker(mapDataType, id)) {
            this.$refs.map.onModifyMarkerAt(mapDataType, mapMarkerData);
          } else {
            this.$refs.map.onCreateMarker(mapDataType, mapMarkerData);
          }
        },
        async getEventPoint() {
          if(this.eventId == undefined) {
            return;
          }
          let params = {
            eventId : this.eventId,
            serverTime : null,
          };
          await fetchPointList( params).then((res) => {
            if (res.data.result == 0) {
              this.pointServerTime = res.data.data.serverTime
              this.pointCount = res.data.data.total
              this.pointList = res.data.data.content
              this.pointList.forEach((element) => {
                element.selectId = element.index;
                element.selectType = "Point";
                element.isOwner = false;
                let pointMapMarker = new MapMarkerData();
                element.name = this.$t(getPointTypeName(element.type));
                pointMapMarker.setPointData(element);
                this.$refs.map.onCreateMarker(MapDataType.ID_POINT, pointMapMarker);
              })
            }
          });
        },
        //endregion

        //region station
        setStationMissionPauseResume(isPause, callback){
            let param = {
              missionId: null,
              stationDeviceId : this.stationDeviceId,
              cls: "dji"
            }
            if(!isPause) {
              fetchStationMissionResume(param).then((res) => {
                console.log("fetchStationMissionResume", res)
                if (res.status == 200 && res.data.result == 0) {
                  console.log('미션 일시중지 해제 성공')
                  callback(true)
                }else {
                  console.log('미션 일시중지 해제 실패')
                  callback(false)
                }
              }).catch((err) => {
                callback(false)
                console.error('미션 일시중지 해제 실패', err.response.data.result, err.response.data.message)
              });
            } else {
              fetchStationMissionPause(param).then((res) => {
                console.log("fetchStationMissionPause", res)
                if (res.status == 200 && res.data.result == 0) {
                  console.log('미션 일시중지 성공')
                  callback(true)
                } else {
                  callback(true)
                  // callback(false)
                }
              }).catch((err) => {
                callback(false)
                console.error('미션 일시중지 실패', err.response.data.result, err.response.data.message)
              });
            }
          },

        checkedStationDebug(){
          if(this.isStationChecked) {
            this.stationDebugAction("DebugModeOpen")
          } else {
            this.stationDebugAction("DebugModeClose")
          }
        },
        setDockStatus(info) {
          if(info == null) {
            return;
          }
          this.isStationChecked = info.modeCode === EDockModeCode.Remote_Debugging.code ? true : false
          this.isDoorOpened = info.coverState === 1 ? true : false
        },
        removeMapMission(){
          this.$refs.map.onDeleteMarkerList(MapDataType.ID_MAPPING_MARKER);
          this.$refs.map.onDeleteMappingLineList(MapDataType.ID_MAPPING_POLYLINE)
          this.$refs.map.onDeletePolyLineList(MapDataType.ID_MAPPING_POLYGON_LINE)
          this.$refs.map.onDeletePolygonList(MapDataType.ID_MAPPING_HIDDEN_POLYGON)
          this.$refs.map.onDeleteLineList(MapDataType.ID_MAPPING_LINE)
        },
        removeMapWaypoint(){
          this.$refs.map.onDeleteMarkerList(MapDataType.ID_WAYPOINT);
          this.$refs.map.onDeleteLineList(MapDataType.ID_WAYPOINT)

        },

        initStationMissionJob(){
          if(this.missionInfo != null && this.missionInfo.missionType === "Mapping") {
            this.removeMapMission()
          }
          if(this.missionInfo != null && this.missionInfo.missionType === "WayPoint") {
            this.removeMapWaypoint()
          }
          this.missionInfo = null;
          this.missionLocationList = [];
          this.stationScheduleInfo = null;
          this.stationMissionJobLog = null;
        },
        getStationMissionJob(){
          let stationDeviceId = this.stationDeviceId;
          fetchStationMissionJob(stationDeviceId).then((res) => {
            if (res.status == 200 && res.data.result == 0) {
              if(res.data.data.stationMissionJobLogId != null) {
                this.stationMissionJobLog = res.data.data.stationMissionJobLog
                let missionId = this.stationMissionJobLog.missionId
                this.getMissionInfo(missionId)
                this.getStationMissionJobStatusInfo()
              }
            }
          });
        },
        getStationChannelManagerInfo(stationDeviceId, type){
          fetchStationDeviceChannelManagerInfo(stationDeviceId).then((res) => {
            if (res.status === 200 && res.data.result === 0) {
              if(type === "dock") {
                this.dockChannelManagerInfo = res.data.data
                this.getChannelInfo( this.dockChannelManagerInfo.channelId, "dock")
              }
              if(type === "drone") {
                if(this.droneChannelManagerInfo != null) {
                  this.$refs.map.onDeleteLineAt(MapDataType.ID_DRONE, this.droneChannelManagerInfo.deviceLogId)
                }
                this.droneChannelManagerInfo = res.data.data
                console.log("droneChannelManagerInfo", this.droneChannelManagerInfo, type)
                if(this.droneChannelManagerInfo.channelId !== null) {
                  this.getChannelInfo( this.droneChannelManagerInfo.channelId, "drone")
                }
              }
            }
          });
        },
        getStationInfo(){
          fetchStationDeviceInfo(this.stationDeviceId).then((res) => {
            if (res.status == 200 && res.data.result == 0) {
              this.stationInfo = res.data.data;
              this.eventId = this.stationInfo.managerData.eventId;
              //화면 추가 정보 불러오기 함수 호출
              //소켓 연결
              this.stationWebsocketSetting();

              //mqtt 연결
              this.stationMqttSetting();


              //현장 정보
              this.getEvent();
              this.getEventPoint();
            }
          });
        },
        stationDebugAction(actionName) {
          let param = {
            "cls" : "dji",
            "deviceSerial" : this.stationInfo.deviceSerial,
            "method" : actionName,
            "action" : null
          }

          this.isModalVisible = true; // 모달 창 표시
          if(param.method != null) {
            fetchStationDeviceDockDebug(param).then((res) => {
              if(res.status === 200 && res.data.result == 0) {
                this.changeDebugModeValue(actionName, true)
              } else {
                this.changeDebugModeValue(actionName, false)
              }
              this.isModalVisible = false; // 모달 창 닫기
            }).catch((error) => {
              this.changeDebugModeValue(actionName, false)
              console.error(error)
              this.isModalVisible = false; // 모달 창 닫기
              // if(actionName === "DebugModeClose"  || actionName === "DebugModeClose") {
              //   ;
              // }
            })
          }
        },
        changeDebugModeValue(actionName, isSuccess) {
          switch (actionName) {
            case "DebugModeOpen" : {
              this.isStationChecked = isSuccess === true ? true : false
              break;
            }
            case "DebugModeClose" : {
              this.isStationChecked = isSuccess === true ? false : true
              break;
            }
          }
        },

        //드론 카메라 모드 변경 비디오 또는 캠처
        setChangeCameraMode(modeName) {
          if(this.storeDroneInfo == null || (this.storeDroneInfo.modeCode !== EModeCode.Manual.code && this.storeDroneInfo.modeCode !== EModeCode.Standby.code)) {
            alert(this.$t('station-mode-alert-camera'))
            return;
          }
          let data = {
            locked: false,
            payloadIndex: this.cameraIndex,
            cameraMode: modeName === "Video" ? 1 :0
          }
          if(this.cameraIndex != null) {
            this.sendStationDevicePayload(PayloadCommandsEnum.CameraModeSwitch, data)
          } else {
            console.error("drone not connected")
          }
        },
        async onClickCameraControl(){
          if(this.missionInfo != null && this.missionInfo.missionType == "Mapping") {
            alert(this.$t('station-mode-alert-mapping'))
            this.isCameraControlChecked = false
            if(this.$refs.cameraGimbal.checked) {
              this.$refs.cameraGimbal.checked = false
            }
            return
          }

          if(this.cameraIndex == null) {
            console.error("drone not connected")
            return
          }
          let send = {
            deviceSerial : this.stationInfo.deviceSerial,
            cls : "dji",
            payloadIndex : this.cameraIndex,
            cameraType : "wide",
            zoomFactor : "2.0",
            cameraMode : 1,
            locked : false,
            pitchSpeed : 0.0,
            yawSpeed : 0.0,
            x : 0.5,
            y : 0.5,
            resetMode : 0
          }
          fetchStationDeviceDockAuthorityPayload(send).then( res => {
            if(res.status === 200 && res.data.result === 0) {
              console.info("fetchStationDeviceDockAuthorityPayload success", res)
            } else {
              console.error("fetchStationDeviceDockAuthorityPayload fail", res)
            }
          }).catch((error) => {
            console.error("fetchStationDeviceDockAuthorityPayload error", error)
          })
        },
        async onClickFightControl() {
          if(this.missionInfo != null && this.missionInfo.missionType == "Mapping") {
            alert(this.$t('station-mode-alert-mapping'))
            this.isDroneFightControlChecked = false
            if(this.$refs.manualRemote.checked) {
              this.$refs.manualRemote.checked = false
            }
            return
          }

          if(this.useManualControlState.stationFlightController) {
            await this.exitFlightControl()
            return
          }

          if(this.storeDroneInfo != null && this.storeDroneInfo.modeCode !== EModeCode.Manual.code && this.storeDroneInfo.modeCode !== EModeCode.Standby.code) {
            this.setStationMissionPauseResume(true, async (isSuccess) => {
              if(isSuccess) {
                await this.enterFlightControl()
              }
            })
          } else {
            await this.enterFlightControl()
          }
        },
        async enterFlightControl () {
          if(this.stationInfo == null) {
            this.isDroneFightControlChecked = false;
            if(this.$refs.manualRemote.checked) {
              this.$refs.manualRemote.checked = false
            }
            return
          }

          try {
            let drcEnterParam = {
              "cls" : "dji",
              clientId: this.clientId,
              dockSerial: this.stationInfo.deviceSerial,
            }
            await fetchStationDrcEnter(drcEnterParam).then(res => {
              if (res.status === 200 && res.data.result === 0) {
                this.useManualControlState.stationFlightController = true
                let data = res.data.data;

                if(data.sub && data.sub.length > 0) {
                  this.deviceTopicInfo.subTopic = data.sub[0]
                }

                if(data.pub && data.pub.length > 0) {
                  this.deviceTopicInfo.pubTopic = data.pub[0]
                }

                console.log("fetchStationDrcEnter", this.resultUseDroneControlWsEvent.droneControlSource, ControlSource.A)
                console.log("fetchStationDrcEnter", this.resultUseDroneControlWsEvent.droneControlSource == ControlSource.A)
                // 비행 제어권 받기
                // if (this.resultUseDroneControlWsEvent.droneControlSource !== ControlSource.A) {
                  let param = {
                    "cls" : "dji",
                    clientId: this.clientId,
                    deviceSerial: this.stationInfo.deviceSerial,
                  }
                  fetchStationDeviceDockAuthorityFlight(param).then(res => {
                    console.log("fetchStationDeviceDockAuthorityFlight", res)
                    if (res.status === 200 && res.data.result === 0) {
                      console.log("request Get flight control successfully")
                    } else {
                      console.error("fetchStationDeviceDockAuthorityFlight", res)
                    }
                  });
                // }
                console.log('Get flight control successfully')
              } else {

                if(this.$refs.manualRemote.checked) {
                  this.$refs.manualRemote.checked = false
                }
                this.isDroneFightControlChecked = false
                this.useManualControlState.stationFlightController = false
                alert(res.data.message)
              }
            })
          } catch (error) {
            console.error(error)
          }
        },
        async exitFlightControl() {
        if(this.stationInfo == null) {
          return
        }
        try {
          await fetchStationDrcExit({
            cls : "dji",
            clientId: this.clientId,
            dockSerial:this.stationInfo.deviceSerial,
          }).then(res => {
            console.log("fetchStationDrcExit", res)
            if(res.status === 200 && res.data.data.result === 0) {
              if (res.data.result === 0) {
                this.isDroneFightControlChecked = false
                if(this.$refs.manualRemote.checked) {
                  this.$refs.manualRemote.checked = false
                }
                this.useManualControlState.stationFlightController = false
                this.deviceTopicInfo.subTopic = ''
                this.deviceTopicInfo.pubTopic = ''
              }
            }
          })
        } catch (error) {
          console.error("exitFlightControl", error)
        } finally {
          if(this.useManualControlState.stationFlightController) {
            this.useManualControlState.stationFlightController = false
          }
        }
      },
        changeShowNameToDroneCameraType(droneCameraType) {
            switch (droneCameraType) {
                case 'WIDE':
                    return 'wide'
                case 'ZOOM':
                    return 'zoom'
                case 'TH':
                    return 'infrared'
                case 'PIP':
                    return 'pip'
                default:
                    return null
            }
        },
        changeDroneCameraTypeToShowName(droneCameraType) {
          switch (droneCameraType) {
            case 'wide':
              return 'WIDE'
            case 'zoom':
              return 'ZOOM'
            case 'infrared':
              return 'TH'
            default:
              return null
          }
        },
        getStationLiveStreamCapacityList(){
          fetchStationLiveStreamCapacityList({}).then(res => {
            if (res.status === 200 && res.data.result === 0) {
              let list = res.data.data
              list.forEach(item => {
                // console.log("fetchStationLiveStreamCapacityList", this.stationInfo.childStationDevice.deviceSerial, item)
                if(item.sn === this.stationInfo.childStationDevice.deviceSerial) {
                  // console.log("fetchStationLiveStreamCapacityList", item)
                  fetchStationDevicePayloadInfo(this.stationInfo.childStationDevice.stationDeviceId).then(res => {
                    if (res.status === 200 && res.data.result === 0) {
                      let payloadInfo = res.data.data[0]
                      if(payloadInfo != undefined) {
                        this.cameraPayloadSerial = payloadInfo.payloadSerial;
                        item.camerasList.forEach(camera => {
                          if(payloadInfo.payloadName === camera.name) {
                            this.cameraSelectButton = this.changeDroneCameraTypeToShowName(camera.videosList[0].type);
                            this.cameraIndex = camera.index;

                            if(camera.videosList[0].switchableVideoTypes.includes("wide")) {
                              if(!this.showButtons.includes("WIDE")) {
                                this.showButtons.push('WIDE')
                              }
                            }
                            if(camera.videosList[0].switchableVideoTypes.includes("zoom")) {
                              if(!this.showButtons.includes("ZOOM")) {
                                this.showButtons.push('ZOOM')
                              }
                            }
                            if(camera.videosList[0].switchableVideoTypes.includes("infrared")) {
                              if(!this.showButtons.includes("TH")) {
                                this.showButtons.push('TH')
                              }
                              if(!this.showButtons.includes("PIP")) {
                                this.showButtons.push('PIP')
                              }
                            }
                          }
                        })
                      }

                      this.resultUseDroneControlWsEvent = new UseDroneControlWsEvent(this.stationInfo.deviceSerial ,this.cameraPayloadSerial)
                    }
                  })
                }
              })
              // cameras.value = res.data.data
              // currentCamera.value = cameras.value[0]
            } else {
              console.error("fetchLiveStreamCapacityList", res)
            }
          });
        },
        sendStationDevicePayload(cmd, data, resultCall) {
          let send = {
            cls : "dji",
            deviceSerial: this.stationInfo.deviceSerial,
            cmd: cmd,
            data: data,
          }
          fetchStationDeviceDockPayloadCommands(send).then(res => {
            console.log("sendStationDevicePayload", res)
            if (res.status === 200 && res.data.result === 0) {
              if(cmd === PayloadCommandsEnum.CameraModeSwitch) {
                this.isCameraShot = data.cameraMode !== 1;
              }
              console.log("sendStationDevicePayload", res)
              if(resultCall !== undefined && resultCall instanceof Function) {
                resultCall(true)
              }
              "";
            } else {
              console.error("fetchStationDeviceDockPayloadCommands", res)
              if(resultCall !== undefined && resultCall instanceof Function) {
                resultCall(true)
              }

            }
          }).catch((error) => {
            console.error("fetchStationDeviceDockPayloadCommands", error)
            if(resultCall !== undefined && resultCall instanceof Function) {
              resultCall(true)
            }

          })
        },
        //endregion

        //region websocket
        stationWebsocketSetting() {
          if(this.useConnectWebSocket != null) {
            this.useConnectWebSocket.onConnectWebSocket(messageHandler, this.eventId);
            this.useConnectWebSocket.onWebSocketMounted();
          }
        },
        //endregion

        //region mqtt
        stationMqttSetting() {
            this.deviceTopicInfo.sn = this.stationInfo.deviceSerial
            this.useMqtt.setStoreMqttState(this.deviceTopicInfo)
        },
        stationUseMqtt() {

        },
        stationMqttUnmounted(){
          if(this.useMqtt != null) {
            this.useMqtt.onUnmounted()
            this.useMqtt = null
          }

          if(this.useConnectMqtt != null) {
            this.useConnectMqtt.onUnmounted()
            this.useConnectMqtt = null
          }

          if(this.useDroneControlMqttEvent != null) {
            this.useDroneControlMqttEvent.onUnmounted()
            this.useDroneControlMqttEvent = null
          }
        },
        //endregion

        //region location
        getLocation(refId){
          if(this.eventId === null) {
            return
          }

          if(this.isLocationReq === true) {
            return;
          }
          this.isLocationReq = true;

          let mapDataType = MapDataType.ID_DRONE;
          let offset = this.$refs.map.onGetLineSize(mapDataType, refId);
          let params = {
            refId: refId,
            eventId: this.eventId,
            offsetIndex: offset,
          };
          fetchLocation(params).then((res) => {
            if (res.data.result == 0) {
              if (res.data.data.content.length > 0) {
                res.data.data.content.sort(function (a, b) {
                  return a.index - b.index;
                })
              }
              this.isLocationReq = false;
              var mapLineData = new MapLineData();
              mapLineData.id = refId;
              mapLineData.locationGroup = res.data.data.content;
              mapLineData.mapDataType = mapDataType;
              mapLineData.isMain = true;
              mapLineData.isShow = true;
              if (this.$refs.map.onIsLine(mapDataType, refId)) {
                this.$refs.map.onModifyLineAddAt(mapDataType, mapLineData);
              } else {
                this.$refs.map.onCreateLine(mapDataType, mapLineData);
              }
            }
            this.isLocationReq = false;
          }).catch((e)=>{
            e;
            this.isLocationReq = false;
          });

        },
        //endregion

        //region player
        getChannelInfo(channelId, type) {
          fetchChannelCheckStream(channelId).then((res)=>{
            let seveType = type
            if(res.data.data.bytesIn == 0 || res.data.data.bytesIn === null) {
              return
            }
            fetchChannel(channelId).then((res) => {
              if (res.data.result === 0) {
                if(seveType === "drone") {
                  this.droneChannelInfo = res.data.data;
                  console.log("droneChannelInfo", this.droneChannelInfo)
                }
                if(seveType === "dock") {
                  this.dockChannelInfo = res.data.data;
                  this.getStationLiveStreamCapacityList();
                }
              } else {
                let message = res.data.message;
                alert(message);
              }
            });
          })


        },
        playerLoadData(id, isLoad) {
          if(this.dockChannelInfo != null && this.dockChannelInfo.channelId == id) {
            this.$set(this.dockChannelInfo, "isLoad", isLoad);
          }
          if(this.droneChannelInfo != null && this.droneChannelInfo.channelId == id) {
            this.$set(this.droneChannelInfo, "isLoad", isLoad);
          }
        },
        //endregion

        //CameraShot 20241127
        capturePhoto(){
          this.showMessage = true;
          setTimeout(() => {
            this.showMessage = false;
          }, 2000);
        },
        toggleRecording() {
          if (this.isRecording){
            clearInterval(this.intervalId);
            this.playTimer = 0; // 시간 초기화
          }
          else {
            // 녹화 시작
            this.intervalId = setInterval(() => {
              this.playTimer++;
            }, 1000);
          }
          this.isRecording = !this.isRecording;
          this.playMessage = true;

          setTimeout(() => {
            this.playMessage = false; // 2초 후 메시지 숨김
          }, 2000);
        },

      playerRemoveId(playerId) {
        if(this.dockChannelInfo.channelId === playerId) {
          this.$set(this.dockChannelInfo, "isLoad", false);
          this.dockChannelInfo = null
        } else if(this.droneChannelInfo.channelId === playerId) {
          this.$set(this.droneChannelInfo, "isLoad", false);
          this.droneChannelInfo = null
        }
      },

        beforeDestroy() {
          if (this.intervalId) {
            clearInterval(this.intervalId);
          }
        },
    },

};
</script>

